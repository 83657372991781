body {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

.body-grey-background {
  background-color: #fafafa;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
}

#SKU a,
#skuMore a,
.project-link {
  color: #0275d8;
  text-decoration: none;
}

.header-container {
  height: 17%;
  position: relative;
}

.container-results {
  height: 75%;
  position: relative;
  min-height: 400px;
}

.occupy-height {
  min-height: 100%;
  position: relative;
}

#header {
  height: 80px;
  background-color: black;
  /*#425563*/
  color: #fff;
}
#headerNew {
  height: 80px;
  background-color: white;
  /*#425563*/
  color: #fff;
}

.recharts-legend-item-text {
  color: #000 !important;
}

footer {
  position: fixed;
  bottom: 0px;
  background-color: #d7d7d7;
  width: 100%;
  /* text-align: center;
  padding-top: 10px; */
  line-height: 1;
  z-index: 9999;
}

footer p {
  margin-bottom: 0.5rem;
}

footer a {
  color: #0066cc;
}

.text-center {
  text-align: center;
}

.container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

#searchcontentbody {
  margin-top: 250px;
}

.searchbtn {
  background-color: #0d5265 !important;
}

.searchLabel {
  font-size: 9px;
  color: #7630ea;
  font-weight: bold;
}

.contentbody {}

.input {}

.username {
  font-size: 15px;
}

.department {
  font-size: 13px;
}

.user-info {
  text-align: right;
  font-size: 12px;
}

th {
  background-color: #614767 !important;
  color: #fff !important;
}

.nav-link.active {
  background-color: #ffffff !important;
  color: #0066ff !important;
  border-bottom: 2px solid #06f;
  border-radius: 0px !important;
  padding-bottom: 2px !important;
  min-width: 75px;
  text-align: center;
}
.Pas-Active-Tab .nav-link.active {
  background-color: #ffffff !important;
  color: #0066ff !important;
  border-bottom: 2px solid #06f;
  border-radius: 0px !important;
  padding-bottom: 2px !important;
  min-width: 75px;
  text-align: center;
  width:100%
}
a {
  color: #333333 !important;
}

.nav-link {
  min-width: 75px;
  text-align: center;
}

/* dynamicData.js css */
.addWorkPkgRowBtn {
  float: right;
  position: relative;
  right: 31px;
  z-index: 99;
}

.workPkgInputField {
  position: relative;
  bottom: 19px;
  height: 7vh;
}

.gp-add {
  position: absolute;
  top: 80px;
  right: 15px;
  background-color: #fff;
}

.gp-acc-txt {
  position: absolute;
  top: 50px;
  left: 75px;
  background-color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  font-size: smaller;
}

.griddle-settings-toggle {
  display: none;
}

tr.griddle-row {
  border-bottom: 1px solid #eaecef;
}

.griddle-cell {
  border: 1px solid #cccfd4;
  padding: 2px;
}

.griddle-cell a {
  color: #0275d8;
}

.griddle-table-heading-cell {
  border: 1px solid #cccfd4;
  padding: 2px;
}

.griddle-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

/* .doc-capsules {
  min-height: 700px !important;
  height: auto !important;
  border: 1px solid #ccc;
  box-shadow: 2px 5px 5px grey;
  background-color: #ffffff;
  margin-bottom: 20px;
} */

/* .capsules {
  
  border: 1px solid #ccc;
  box-shadow: 2px 5px 5px grey;
  background-color: #ffffff;
  min-height: 700px;
  height: auto;
  position: relative;
  height: 100vh;
  margin-bottom: 20px;
} */
.capsules{
  padding-top: 8px;
  height: 83.8vh;
}
.capsules, .doc-capsules {
  border: 1px solid #ccc;
  box-shadow: 2px 5px 5px grey;
  background-color: #fff;
  margin-bottom: 20px;
background: linear-gradient(to right, #fff, #eee);
}

.doc-capsules {
  height: 88vh;
  overflow-y: auto;
}

#pills-accelerator .doc-depo-div-file-select {
  margin-bottom: 10%;
}

#sectionheader {
  background-color: #01a982;
  /*  0d5265 */
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  padding: 5px;
  border-top-right-radius: 3px;
  text-align: left;
  font-weight: 800;
}

.card-header {
  padding: 0px !important;
  background-color: #f9f9f9;
  /*f1f1f1*/
  margin: 3px !important;
  border-radius: 4px !important;
  border: 1px solid #ccc !important;
}

.card {
  border: 0px !important;
}

.color-blk {
  color: #000000 !important;
}

.btn {
  font-size: 12px !important;
}

.btn-12 {
  font-size: 12px !important;
  float: right;
}

.collapse.show {
  border-bottom: 1px solid #ccc;
}

.btn-outline-success {
  border-color: #01a982 !important;
  color: #01a982 !important;
}

.btn-outline-success:hover {
  background-color: #01a982 !important;
  color: #fff !important;
} 

.form-control-sm,
.react-autosuggest__input {
  border-radius: 0px !important;
  height: 100px;
  font-size: 12px;
}

.search-base {
  background-color: #fff;
}

.select-search-base {
  font-size: 12px;
  height: 1.55rem !important;
}

.bg-image-search {
  background-image: url(https://www.hpe.com/content/dam/hpe/shared-publishing/homepage-v3/marqueeCard/Marquee_IND_Greenlake_4-26-2019.jpg.hpetransform/crop:bounds=0%25,5%25,100%25,79%25~bounded-resize:width=1600/image.orig);
}

.home-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: -20px;
}

/* @import url(https://use.fontawesome.com/releases/v6.6.0/css/all.css); */
/* @import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css); */
.mb-0>button{
  text-decoration: none !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.mb-0>button:before {
  float: left !important;
  font-family: inherit;
  content: "-";
  padding-right: 5px;
  font-size: 14px !important;
  font-weight: bold;
  border:none !important;
  outline:  none !important;
}

.mb-0>button.collapsed:before {
  float: left !important;
  content: "+";
  font-size: 14px !important;
  border:none !important;
  outline:  none !important;
}

.mb-0>button:hover,
.mb-0>button:active,
.mb-0>button:focus
{
  /* text-decoration: none !important; */
  border:none !important;
 outline:  none !important;
}

.pdf-file {
  font-size: 1.5vw;
  color: #e60808 !important;
  margin-right: 5px;
}

.word-file {
  font-size: 1.5vw;
  color: #1084c8 !important;
  margin-right: 5px;
}

.ppt-file {
  font-size: 1.5vw;
  color: #f5856e !important;
  margin-right: 5px;
}

.xl-file {
  font-size: 1.5vw;
  color: #01a982 !important;
  margin-right: 5px;
}

.mail-file {
  font-size: 1.5vw;
  color: #e6d92d !important;
  margin-right: 5px;
}

.file-ext-link {
  font-size: 1.5vw;
  color: #5c7cfa !important;
  margin-right: 5px;
}

.btn-link {
  color: #000000 !important;
}

/* [data-toggle="collapse"]:not(.collapsed) {
  color: #01a982 !important;
  font-weight: bold !important;
} */
.searchresults {
  overflow-y: auto;
  overflow-x: hidden;
  height: 73.2vh;
  position: relative;
  max-height: 700px;
}

.searchresults .card-header {
  border-radius: unset !important;
}

.customerCapsulesearchresults {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 67%;
  position: relative;
  max-height: 260px;
}

.customerCapsulesearchresults .card-header {
  border-radius: 0 !important;
}
.overflow-auto {
  overflow: auto;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-button {
  background: #ccc;
  height: 0px !important;
}

::-webkit-scrollbar-track-piece {
  background: #ccc;
  height: 0px !important;
}

::-webkit-scrollbar-thumb {
  background: #01a982;
}

.btn-full {
  width: 100%;
  text-align: left;
}
.react-autosuggest__input{
    width: 90%;
    height: 25px;
    padding: 0.25rem 2.5rem;
    font-family: Helvetica,sans-serif;
    font-weight: 300;
    font-size: 12px;
    border: 1px solid #aaa;
    border-radius: 0.2rem;
    color: #464a4c;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
}


.homeAutoSuggest {
  margin-left: 168px !important;
  width: 40%;
  /* margin-top: 2%; */
  /* height: 500px; */
  padding: 0.25rem 0.5rem;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 30px;
  border: none;
  /* border: 1px solid #aaa;
  border-radius: 0.2rem; */
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;  
}

/* .internal-user-home-search input {
  width: 81% !important;
  background-color: #fff !important;
  border: 1px solid #606060 !important;
  height: 44px !important;
  border-radius: 11px !important;
  padding-right: 30px;
  font-size: 18px;
  margin-left: 7.3%; */
  /* margin-top: 1.3%; */
/* } */

/* @media only screen and (min-width: 768px) {
  #SearchAutoSuggestionsPrimary input {
    width: 440px;
  }
  #SearchAutoSuggestionsPrimary .react-autosuggest__suggestions-container {
    width: 440px;
  }
  #SearchOppIDPrimary input {
    width: 440px;
 }
} */
.dropdown-toggle::after {
  display: none !important;
}
#SearchOppIDPrimary input {
  width: 100%;
  padding-right: 0px !important;
  background-color: #fff !important;
  border: 1px solid #606060 !important;
  height: 30px !important;
  border-radius: 0px !important;
  /* width: 100%;
  height: 35px;
  padding: 0.25rem 0.5rem;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 12px;
  border: 1px solid #aaa;
  border-radius: 0.2rem;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  left:10; */
}

/* .internal-user-home-search input::placeholder {
  font-size: 0.75em;
} */

#SearchOppIDPrimary input::placeholder {
  font-size: 0.75em;
}

/* #SearchOppIDPrimary input::placeholder {
  font-weight: bold;
} */
#SearchOppID input {
  padding-right: 28px !important;
  background-color: #fff !important;
  border: 1px solid #606060 !important;
  height: 30px !important;
  border-radius: 1.2rem !important;
  height: 25px !important;
  font-size: 12px !important;
}

#SearchAutoSuggestions input {
  padding-right: 27px !important;
  border-radius: 1.2rem !important;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
  /* width: 97% !important;
  position: relative;
  left: 17px; */
}

.homesearch-autosuggest .react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  max-height: 350px !important;
  overflow: auto;
  margin-left: 0.3% !important;
  /* bottom:249px !important; */
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 102%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 1vw;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  max-height: 250px;
  overflow: auto;
  margin-left: -3%;
}

#SearchOppIDPrimary .react-autosuggest__suggestions-container--open {
  width: 94.5%;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: .50vw 1vw !important;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.categoryicons {
  font-size: 22px;
  color: #333;
}

.cust-case-open {
  background-color: #01a982;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #01a982, #a6d3cb);
  border: solid 1px #ccc !important;
  cursor: pointer;
}

.cust-case-closed {
  background-color: #8c8c8c;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #8c8c8c, #c2c2c2);
  border: solid 1px #ccc !important;
  cursor: pointer;
}

.cust-case-elevated {
  background-color: #fec901;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  background-image: linear-gradient(to right, #eea800, #fec901);
  border: solid 1px #ccc !important;
  /* cursor: pointer; */
}

.fa-external-link-alt {
  cursor: pointer;
}

.cust-case-escalated {
  background-color: #ff8300;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  background-image: linear-gradient(to right, #ff8300, #ffa13e);
  border: solid 1px #ccc !important;
  /* cursor: pointer; */
}

.cust-case-sev1 {
  background-color: #f30;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  background-image: linear-gradient(to right, #ff3300, #ff7451);
  border: solid 1px #ccc !important;
  /* cursor: pointer; */
}

.btn_vs {
  font-size: 12px;
  padding: 2px;
}

.overall-status {
  background-color: #32dac8;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 8px;
}

.btn_vs {
  font-size: 12px;
  padding: 2px;
}

.overall-status {
  background-color: #32dac8;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 8px;
}

.revenue-text {
  font-size: 12px;
  font-weight: normal;
}

.opp-rev {
  font-size: 12px;
}

.opp-rev-won {
  background-color: #32dac8;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 2px;
}

.opp-rev-lost {
  background-color: #7e8285;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 2px;
}

.hidden {
  display: none;
}

.overall-status {
  background-color: #32dac8;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 8px;
}

.revenue-text {
  font-size: 12px;
  font-weight: normal;
}

.opp-rev {
  font-size: 12px;
}

.opp-rev-won {
  background-color: #32dac8;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 2px;
}

.opp-rev-lost {
  background-color: #7e8285;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 2px;
}

.psa_overall_green {
  background-color: #01a982;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 5px;
  cursor: default;
}

.psa_overall_amber {
  background-color: #ff8300;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 5px;
  cursor: default;
}

.psa_overall_red {
  background-color: #f30;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 5px;
  cursor: default;
}

.psa_overall_grey {
  background-color: #fff;
  border: solid 1px #999da1 !important;
  color: #a5a0a0;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 5px;
  cursor: default;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 320px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.col-4-no-margins {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.no-margins {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.row-padding-fix {
  padding-left: 15px;
  padding-right: 15px;
}

.sev-margin {
  margin-left: 14px;
  margin-right: 17px;
}

.full-data-modal {
  max-width: 95%;
}

.sku-data-modal {
  max-width: 65%;
}

.hpesc-data-modal {
  max-width: 50%;
}

.circle-count {
  width: 40px;
  height: 25px;
  background-color: #fff;
  border-radius: 60px;
  position: relative;
  display: inline-block;
  margin: 4px;
  color: #000000;
  /*#01a982*/
  border: 1px solid #01a982;
  /*dedede*/
}

.count-inner {
  font-size: 12px;
  /* text-transform: uppercase; */
  margin: auto;
  height: 10px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  text-align: center;
  float: right;
}

.in-flex {
  display: flex;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0px rgba(2, 117, 216, 0.25) !important;
}

.img-fluid {
  opacity: 1;
}

.acc-card-outline {
  margin-bottom: 15px;
  margin-top: 12px;
}

.acc-card-base {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin-top: 5px;
  margin-bottom: 5px;
  padding-bottom: 2px;
}

.acc-name {
  font-size: 13px;
  text-transform: uppercase;
}

.fc-acc-name {
  font-size: 13px;
}

.ac-name-green {
  color: #01a982;
  font-weight: bold;
}

.onboardprompt {
  font-size: 16px;
  color: #cc0000;
}

#sectionheader2 {
  background-color: #0d5265;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  padding: 5px;
  border-radius: 4px;
}

#sectionheader3 {
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: normal;
  padding: 5px;
  border-top-right-radius: 3px;
}

.border-none {
  border: none !important;
}

.border-bottom {
  border-bottom: 1px solid #ccc;
}

.docbrief {
  width: 25px;
  height: 25px;
  background-color: #0d5265;
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
}

.docbrief-open {
  width: 25px;
  height: 25px;
  background-color: #a0a0a0;
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
}

.right-divide {
  border-right: 1px solid #ccc;
}

.nodocspace {
  background-color: beige;
  text-align: center;
  /* height: 50px; */
}

.docan-btn {
  background-color: #5f7a76 !important;
  width: max-content;
  font-size: 13px !important;
  margin-top: -1px !important;
}

.doc-title {
  width: 100%;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.doc-title-active {
  width: 100%;
  /* border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc; */
  background-color: #f1f1f1;
  color: #000;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.card {}

.text-align-th {
  text-align: center !important;
}

.txt-selective-yellow {
  color: #fbdb3e;
  font-size: 10px;
}

.modal-lg {
  max-width: 95vw !important;
}

.modal-graph {
  max-width: 800px !important;
}

.f-ten {
  font-size: 10px !important;
}

.f-thirteen {
  font-size: 13px !important;
}

.loading-img {
  height: 50px;
  position: fixed;
  top: 50%;
}
.loading-book {
  height: 100px;
  position: fixed;
  top: 50%;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  cursor: pointer;
  align-content: center;
}

.tcv-tile {
  border: solid 1px #ccc;
  border-radius: 5px;
  height: 50px;
  background-color: #33dac8;
}

.icon-rings {
  border: 1px solid #ccc;
  border-radius: 25px;
  height: 30px;
  font-size: 15px;
  background-color: white;
}

.fin-text {
  font-size: 14px;
  color: white;
}

.cost-tile {
  border: solid 1px #ccc;
  border-radius: 5px;
  height: 50px;
  background-color: #c140ff;
}

.egv-tile {
  border: solid 1px #ccc;
  border-radius: 5px;
  height: 50px;
  background-color: #ff8201;
}

.fin-text {
  font-size: 15px;
  line-height: 20px;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px #000;
}

.tcv-tile {
  border: solid 1px #ccc;
  border-radius: 5px;
  height: 50px;
  /*background:url(../img/texture.PNG) no-repeat; */
  background-color: #15414c;
}
h5 {
  font-weight: bold;
  font-size: 3vh;
}

.welcomeBanner{
  font-weight: bold !important;
  font-size: 3vh !important;
}

.document-data-modal {
  position: relative;
  flex: 1 1 auto;
  max-height: 70vh;
  padding: 0px;
  overflow-y: hidden !important;
  /* overflow: auto; */
  overflow-x: hidden;
}

.pointer {
  cursor: pointer;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.export-csv {
  float: right;
  background-color: #12a512;
}

.export-csv a {
  color: white;
}

.exp-link a {
  color: #fff !important;
  text-decoration: none;
}

.embed-container {
  position: absolute;
  height: 85%;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
}

.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.search-result-capsule {
  border: 1px solid #ccc;
  border-radius: 12px;
  text-align: center;
  background-color: #fff;
}

.no-margin {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.table-condensed {
  font-size: 10px;
}

.table td,
.table th {
  vertical-align: middle;
}

.icons-sizes {
  font-size: 18px !important;
}

.doc-nature {
  font-size: 9px !important;
}

.left-border {
  border-left: 1px solid #01a982;
}

.top-accname {
  background-color: #614767 !important;
  color: #fff !important;
}

.more-wrapper {
  line-height: 1;
}

.bootbox-input {
  font-size: 14px;
}

.modal-title {
  font-size: 16px;
}

.bootbox-close-button {
  display: none !important;
}

.bootbox-confirm .modal-content {
  width: fit-content;
}

.like-active {
  color: green;
}

.like-down {
  color: red;
}

.table-bordered td,
.table-bordered th {
  padding: 2px;
}

.pas-table-bordered td {
  padding: 5px;
  border-width: 1px;
}

.bootbox .btn {
  font-size: 12px !important;
}

.table-condensed th td {
  padding: 3px;
}

.badge-doctype {
  font-size: 10px;
  background-color: #f1f1f1;
  padding: 2px !important;
  border-radius: 3px;
  font-style: italic;
  font-weight: bold;
  display: inline;
}

.main-container {}

.home-anps-logo {
  width: 500px;
}

.display-none {
  display: none;
}

.usefullinks {
  position: absolute;
  bottom: 13px;
  /*40px*/
  background-color: #f6f6f6;
  border-top: 2px solid #f1f1f1;
  margin-bottom: 15px !important;
}

.footerlink::before {
  display: flex;
  flex-wrap: wrap;
  align-content: left;
  /* content: "A&PS Links"; */
  writing-mode: vertical-lr;
  font-size: 13px;
  color: darkgray;
  font-weight: bold;
  background-color: rgba(241, 242, 247, 0.5);
  margin-left: 5px;
  transform: rotate(180deg);
}

.footerlinks {
  margin-left: -50px;
}

.container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.feedbacktitle {
  width: 100%;
  border-radius: 5px;
}

.bootbox .modal-content {
  width: 620px !important;
}

.usefullinks-after-results {
  background-color: #f2f2f2;
  border-top: 1px solid #ccc;
  top: 0px;
  margin-bottom: 15px !important;
}

.footer-results {
  background-color: #d7d7d7;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  line-height: 1;
  padding-bottom: 2px;
}

textarea.form-control,
input#feedbacktitle {
  font-size: 12px;
}

.pagination {
  display: inline-block;
  list-style: none;
  padding-left: 11vh;
  margin: 2vw 0;
  border-radius: .5vw;
}


.pagination>li {
  display: inline;
}

.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
  color: #777777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  z-index: 3;
  color: #fff !important;
  background-color: #337ab7;
  border-color: #337ab7;
  cursor: default;
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.export-button {
  float: right;
  text-indent: -9999px;
  line-height: 0;
  background-color: #01a982 !important;
  color: #fff;
  cursor: pointer;
}

.export-button:after {
  content: "Export";
  text-indent: 0;
  display: block;
  line-height: initial;
}

.footer-after-results {
  position: relative;
  padding-bottom: 10px;
}

.reset-btn {
  vertical-align: -webkit-baseline-middle;
  color: #d58c06;
  cursor: pointer;
}

.Install-Chart-Layout .btn {
  color: #fff !important;
  cursor: pointer;
}

.Install-Chart-Layout {
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.brief-case {
  width: 20px;
  height: 18px;
}

.sow-icon {
  width: 25px;
  height: 23px;
}

.brief-case-after-results {
  width: 30px;
  height: 30px;
  margin-right: -2.5rem;
  right: 20px;
}

/* doc depo */
/*.body{
              background-color:#FFF !important;
}*/
.container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

#searchcontentbody {
  margin-top: 250px;
}

.nav-link.active {
  background-color: #00a982 !important;
  color: #fff !important;
  border-bottom: 2px solid #00a982;
  border-radius: 0px !important;
  padding-bottom: 4px !important;
  min-width: 75px;
  text-align: center;
  border-radius: 3px !important;
}

.nav-link {
  min-width: 75px;
  text-align: center;
}

.taxonomywindow {
  position: absolute;
  top: 50px;
  width: 300px;
  left: 265px;
}

.pendingreview {
  font-size: 10px;
  color: #ff6600;
}

.approved {
  font-size: 10px;
  color: #00a982;
}

.rejected {
  font-size: 10px;
  color: #f00;
}

.doc-depo-div-file-select {
  position: relative;
  /* height: 30px;
  line-height: 30px;
  text-align: center; */
}

.doc-depo-input-upload {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.file-zip {
  font-size: 1.5vw;
  margin-right: 5px;
  color: #7650b4 !important;
}


#accordionSKUCategories .btn,
#accordionGuideCategories .btn,
#accordionPresentationCategory .btn,
#accordionServiceCategories .btn,
#accordionInsightStages .btn {
  font-size: 12px !important;
}

#accordionSKUCategories .card-body,
#accordionGuideCategories .card-body,
#accordionPresentationCategory .card-body,
#accordionServiceCategories .card-body,
#accordionInsightStages .card-body {
  margin-left: 10px !important;
}

#TipsDataModal .table td,
.table th {
  border: 0px !important;
}

#TipsDataModal .modal-body {
  flex: 1 1 auto;
  padding: 15px;
  position: relative;
  overflow-y: auto;
  max-height: 600px;
  padding: 15px;
}

#TipsDataModal .modal-title {
  font-size: 18px !important;
}

.tips-icon {
  float: right;
  position: absolute;
  top: 5px;
  right: -18px;
  z-index: 0;
  height: 20px;
  display: none;
}

#SearchAutoSuggestions .tips-icon {
  top: 4px;
  right: -19px;
  height: 18px;
}

.circle-count-sub {
  width: 40px;
  height: 16px;
  background-color: #fff;
  border-radius: 60px;
  position: absolute;
  display: inline-block;
  margin: 5px;
  color: #01a982;
  border: 1px solid #dedede;
  right: 15px;
}

.count-inner-sub {
  font-size: 10px;
  margin: auto;
  height: 10px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  text-align: center;
  float: right;
}

.taxonomywindow {
  position: absolute;
  top: 50px;
  width: 300px;
  left: 265px;
}

.tax-modal-content {
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.tax-modal-headers {
  color: #00a982;
}

.tax-modal-border {
  border-bottom: 1px solid #ccc;
}

.pendingreview {
  font-size: 10px;
  color: #ff6600;
}

.approved {
  font-size: 10px;
  color: #00a982;
}

.rejected {
  font-size: 10px;
  color: #f00;
}

.box-border {
  border: 1px solid #ccc;
}

.essential-fontsizes {
  font-size: 12px !important;
  color: #000 !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

.docname-header {
  font-size: 12px !important;
}

.react-datepicker__tab-loop {
  z-index: 2000;
}

.doc_source {
  font-size: 10px !important;
  color: black;
}

.sourcetxt {
  font-weight: bold;
}

/* .doc-analysis-section {
  border: 1px solid #ccc;
} */
.Doc-Depo-Heading {
  cursor: pointer;
  text-decoration: none;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tootipicon {
  color: #ffffff !important;
}

.hiddenRow {
  padding: 0 4px !important;
  background-color: #fcfcfc;
  font-size: 10px;
}

.rounded-btn {
  border-radius: 20px;
  font-size: 12px !important;
  font-weight: bold;
  background-color: white;
  border: 1px solid #ccc;
}

.rounded-btn-active {
  border-radius: 20px;
  font-size: 12px !important;
  font-weight: bold;
  background-color: #7630ea;
  color: #ffffff;
  border: 1px solid #ccc;
}

/* .table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #eceeef;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #eceeef;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #eceeef;
}
th {
  background-color: #01a982;
  color: #fff;
}
th {
  text-align: left;
}
.Doc-Depo-Heading{
              cursor: pointer;
              text-decoration: none;
}

/* Doc Analysis */
.doc-analysis .card {
  border: 0px !important;
}

.doc-analysis .btn-link {
  font-weight: 400 !important;
  color: #0275d8 !important;
  border-radius: 0;
}

.doc-analysis .card-header {
  padding: 0px !important;
  background-color: #fff !important;
  border: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.doc-analysis-section .mb-0>button.collapsed:before,
.doc-analysis-section .mb-0>button:before {
  display: none;
}

.sharepoint-btn {
  padding: 0px;
  background-color: white;
  font-size: 12px !important;
  border: 0px;
  margin: 0px;
  color: #000000 !important;
}

.sharepoint-accordian-body {
  height: 200px;
  overflow: auto;
}

.doc-list-container {
  height: 500px;
  /* height: 500px; */
  margin-left: 13px;
  overflow: auto;
}

/* customer capsule table */
.tbheaders {
  background-color: #0d5265;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  border-radius: 4px;
  padding: 5px !important;
  text-transform: uppercase;
}

.tbheadersnoborderrad {
  background-color: #0d5265;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  padding: 5px !important;
  text-transform: uppercase;
}

.servicetbheadersnoborderrad {
  background-color: #614767;
  color: #fff;
  font-size: 11px;
  padding: 5px !important;
  text-transform: capitalize;
}

.tbheadersblue {
  background-color: #00a982;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  /* border-radius: 4px; */
  padding: 5px !important;
  text-transform: uppercase;
  border-bottom: 2px solid #fff;
}

.tbsubheader {
  color: #333;
  font-weight: bold;
  font-size: 12px;
  padding: 5px !important;
  text-transform: uppercase;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.table-font-size td {
  font-size: 12px;
}

/* Footer Links */
.footerMainDiv {
  padding-bottom: 5px !important;
  z-index: 2;
  /*20px*/
}

/* sku section */
.styledAnchor {
  color: #000000 !important;
  text-decoration: none;
  font-size: 11px !important;
  padding-right: 6px !important;
}

.styledAnchor:hover {
  color: #0275d8 !important;
}

.iColor {
  color: #7e8285 !important;
}

#accordionSharepoint .btn-link {
  color: #333333 !important;
}

.selected-tab-link {
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: bold !important;
  text-decoration: underline;
}

.styledAnchor {
  color: #000000 !important;
  text-decoration: none;
  font-size: 11px !important;
  padding-right: 6px !important;
}

.styledAnchor:hover {
  color: #0275d8 !important;
}

.iColor {
  color: #7e8285 !important;
}

#accordionSharepoint .btn-link {
  color: #333333 !important;
}

.selected-tab-link {
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: bold !important;
  text-decoration: underline;
}

/* Clearable text inputs */
.search-box,
.close-icon,
.search-wrapper {
  position: relative;
}

.search-wrapper {
  width: 100%;
  margin: 0 auto;
}

.search-box {
  width: 100%;
  border: 1px solid #ccc;
  outline: 0;
  border-radius: 15px;
}

.search-box:focus {
  box-shadow: 0 0 15px 5px #b0e0ee;
  border: 2px solid #bebede;
}

.close-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: contents;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}

.close-icon:after {
  content: "X";
  display: block;
  width: 15px;
  height: 15px;
  position: relative;
  background-color: #d9534f;
  z-index: 1;
  right: -193px;
  top: -21px;
  bottom: 0;
  margin: auto;
  padding: 2px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: normal;
  font-size: 10px;
  box-shadow: 0 0 1px #e50f0f;
  cursor: pointer;
  padding-bottom: 15px;
}

.search-box:not(:valid)~.close-icon {
  display: none;
}

/* Side Filter */
.divmsgbtn {
  position: absolute;
  top: 70px;
  display: block;
  z-index: 1000;
  left: -5px;
}

#divMsg {
  background-color: rgb(255, 255, 255);
  color: #000;
  height: 650px;
  width: 305px;
  text-align: center;
  position: absolute;
  left: 15px;
  z-index: 1;
  top: 102px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  /* display: none; */
}

.overflowStyle {
  height: 200px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.overflowStyle2 {
  max-height: 150px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.cardbg {
  background-color: #f1f1f1 !important;
  margin-bottom: 5px !important;
  border-radius: 10px !important;
}

.cardBorder {
  border-radius: 10px !important;
  border: 2px solid #f1f1f1;
  margin-bottom: 5px !important;
}

.cardBorder>button {
  font-size: 12px !important;
}

.custom-btn {
  font-size: 10px !important;
  line-height: 0.5;
  font-weight: 600;
}

.custom-datePicker {
  width: 100px !important;
  margin-right: 5px !important;
  background-image: url("../img/calendar_icon.png") !important;
  background-size: 12px !important;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position-x: right;
  font-size: 11px !important;
}

.d1::before {
  content: "Created From" !important;
  font-size: 14px;
}

.d2::before {
  content: "Created Till" !important;
  font-size: 14px;
}

.d3::before {
  content: "Published From" !important;
  font-size: 14px;
}

.d4::before {
  content: "Published Till" !important;
  font-size: 14px;
}

.d5::before {
  content: "Last Modified From" !important;
  font-size: 14px;
}

.d6::before {
  content: "Last Modified Till" !important;
  font-size: 14px;
}

.font11 {
  font-size: 11px !important;
}

.poccentral-table a {
  color: #0275d8;
  text-decoration: none;
}

.mutlisepct-topbox {
  min-width: 150px;
  position: absolute;
  top: 50px;
  right: 10px;
}

.select-item {
  float: left;
  width: 100%;
}

span.item-renderer {
  float: left;
}

.dropdown-heading-value span {
  text-align: right;
  line-height: 24px !important;
  padding-left: 20px !important;
}

.dropdown-heading {
  height: 24px !important;
}

.select-item {
  padding: 0px 10px !important;
}

.mutlisepct-topbox2 {
  /* min-width: 210px !important; */
  position: absolute !important;
  top: 50px !important;
  right: 10px !important;
}

.dropdown-heading-value {
  line-height: 25px !important;
  position: relative !important;
  color: #fff !important;
  padding-right: 6px !important;
}

.dropdown-heading {
  background-color: transparent !important;
  border-color: transparent !important;
  border-radius: 0px !important;
  border-width: 0px !important;
  border-style: none !important;
}

.dropdown-heading-dropdown-arrow {
  border-color: #fff transparent transparent transparent !important;
  display: flex;
  margin-top: -20px !important;
  float: left;
  position: absolute !important;
}

.dropdown-heading-loading-container {
  display: none !important;
}

.auto-cursor {
  cursor: auto !important;
}

.close-btn-fliter {
  font-size: 14px;
  cursor: pointer;
}

.filter-heading {
  font-size: 14px;
  text-align: left;
}

.clear-all-filters {
  text-align: left;
  cursor: pointer;
  color: #0077ba;
}

.doc-depo-icon {
  float: right;
  margin-right: 0px;
  position: absolute;
  right: 35px;
}

.filtertags {
  background-color: #0e5265;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 11px;
  color: #fff;
  display: inline-block;
  max-width: fit-content;
  margin-bottom: 1px;
}

.project_detailstxt_hgl1 {
  padding: 8px;
  font-size: 11px;
  background-color: #0d5265;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

.project_detailstxt_hgl1 a {
  color: #fff;
}

.prid_text_highlight {
  font-weight: bold;
  color: #00b388;
  text-decoration: underline;
}

.breakword {
  word-break: break-word;
}

/*Refine Search
div#SearchOppIDPrimary > input {
  width: 575px;
}
*/
/*power search*/
.btnpowerserachicon {
  display: inline;
  background: transparent;
  /* border-radius: 26px; */
  border: none;
  cursor: pointer;
  margin-top: 0.25rem;
}

.powersearchfield {
  display: block;
  width: 96%;
  padding: 0.5rem 0.75rem;
  font-size: 12px;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  margin-top: 5px;
  margin-left: 1vw;
}

.powerSearch tr{
  border: none !important;
}

.powersearchfield::placeholder {
  padding-left: 2px;
}

#BtnPowerSearch {
  background: #0d5265;
  border-radius: 0.25rem;
  border: 1px solid darkgreen;
  float: right;
  padding: 2px;
  width: 140px;
  margin-top: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}

#BtnPowerSearch:disabled {
  background: #dddddd;
  border: 1px solid #dddddd;
}

#ClearBtnPowerSearch {
  position: absolute;
  background: #fa9595;
  border-radius: 0.25rem;
  border: 1px solid #fa9595;
  padding: 2px;
  width: 60px;
  margin-top: 5px;
  margin-left: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  right: 163px;
}

.fontx9 {
  font-size: .7vw !important;
}

.fontx10 {
  font-size: 10px !important;
}

.fontx11 {
  font-size: 11px !important;
}

.fontx12 {
  font-size: 1vw !important;
  margin-bottom: 0rem !important;
}

.fontx14 {
  font-size: 14px !important;
}

.modalCloseBtn {
  text-decoration: none !important;
  margin-left: 90%;
}

.float {
  position: absolute;
  width: 50px;
  height: 50px;
  /* top:4px; */
  /* right:0px;  */
  /* top:270px; */
  top: 0px;
  right: 40px;
  /* background-color:#000; 0c9 */
  color: #fff;
  /* border-radius:50px; */
  text-align: center;
  /* box-shadow: 2px 2px 3px #999; */
  /* z-index:999; */
  /* border:2px solid #0c9; */
  margin-right: 8px;
}

.my-float {
  margin-top: 10px;
  width: 30px;
  height: 30px;
}

.filter-list-item {
  color: #000;
}

div#productdetailsModal .modal-dialog {
  max-width: 800px !important;
}

.btn-transp {
  background: transparent;
}

.filterInput {
  border: 0.1vh solid lightgray;
  margin-bottom: 2vh;
  font-size: 2vh;
}

.loading-text {
  margin: auto;
  text-align: center;
  /* margin-top:40%; */
  font-size: 14px;
}

.ibheadertext, .rateTxt {
  font-weight: bold;
  font-size: 2vw;
  float: left;
}

.mtop-5 {
  margin-top: -5px !important;
}

#GriddleProddet>div>table>thead>tr>.griddle-table-heading-cell {
  text-transform: uppercase;
  background-color: #0d5265;
  color: #fff;
}

#GriddleProddet>div>table {
  border: 1px solid #eceeef;
  border-collapse: collapse;
  background-color: transparent;
}

#GriddleProddet>div>table>thead>tr>th,
#GriddleProddet>div>table>thead>tr,
#GriddleProddet>div>table>tbody>tr>td,
#GriddleProddet>div>table>tbody>tr {
  border: 1px solid #eceeef;
}

#GriddleProddet>div>table>tbody>tr>td {
  font-size: 11px;
  padding: 0.3rem;
}

#GriddleProddet>div>table>thead>tr>th {
  padding: 0.3rem;
}

#GriddleProddet>div>.griddle-filter {
  margin-bottom: 3px;
  border: 1px solid lightgray;
}

#GriddleProddet>div>table>tbody>tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.react-datepicker__year-read-view--down-arrow {
  border-width: 7px !important;
}

.table-filter-icon {
  border-top: 5px solid #f1f1f1 !important;
  border-right: 4px solid transparent !important;
  border-left: 4px solid transparent !important;
  box-shadow: inset 0 4px #f1f1f1 !important;
  margin-top: 3px !important;
}

.table-filter-parent {
  /* right: 85px !important; */
}

@media (min-width: 576px) {

  #productdetailsModal,
  #salesCategoryModal {
    max-width: 700px;
    margin: 30px auto;
  }
}

.visio-icon {
  height: 20px;
  width: 40px;
}

.breakall_word {
  word-break: break-word;
}

.wordcloud_error {
  color: gray;
  text-align: center;
  padding: 20;
}

.camelcasing {
  text-transform: capitalize !important;
}

/* CX Survey */
.survery_countnbr {
  font-size: 18px;
  font-weight: bold;
  color: #0070c0;
}

.promoter_score {
  font-size: 17px;
  font-weight: bold;
  color: #be9701;
}

.surveybox {
  border: 1px solid #ccc;
  background-color: #fcfcff;
}

.totalcustexptxt {
  font-weight: bold;
  color: #717171;
  height: 30px;
  line-height: 30px;
}

.percentgreentxt {
  font-weight: bold;
  color: #00b388;
}

.percentambertxt {
  font-weight: bold;
  color: #ffc000;
}

.percentredtxt {
  font-weight: bold;
  color: #ff0000;
}

.smallbox_no {
  border: 1px solid #ccc;
}

.survey-top-pic {
  padding-left: 12px;
  line-height: 30px;
}

/* service category */
.fileurl {
  text-decoration: underline !important;
  color: #0275d8 !important;
}

.insight-txtcolors {
  color: #00b388 !important;
}

.lnht12x {
  line-height: 12px !important;
}

.default-anchor-style {
  text-decoration: underline !important;
}

.mw90 {
  min-width: 90px !important;
}

.mw100 {
  min-width: 100px !important;
}

.mw150 {
  min-width: 150px !important;
}

.mw200 {
  min-width: 200px !important;
}

.mw250 {
  min-width: 250px !important;
}

.mw300 {
  min-width: 300px !important;
}

.mw350 {
  min-width: 350px !important;
}

.lgtable>div>.table-filter-icon {
  /* margin-top: 12px !important; */
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #0d5265;
  color: #fff;
  font-size: 12px;
  position: absolute;
  padding: 8px 8px 8px 8px;
  bottom: -1.6em;
  left: 200%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #cccfd4;
  opacity: 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  z-index: 99999;
  visibility: hidden;
  font-weight: 400;
}

[data-title] {
  position: relative;
}

.star_align {
  display: flex;
  position: absolute;
  left: 65%;
  margin-left: -36px;
}

.like_align {
  display: flex;
  left: 50%;
  margin-right: -36px;
}

.break-word {
  word-break: break-word !important;
}

.alignCenter {
  position: absolute;
  left: 65%;
  margin-left: -11px;
  margin-top: 9px;
}

.header_btn {
  background-color: transparent;
  border: none;
  color: white;
  padding-bottom: 5px;
}

.header-word-break {
  display: block;
  word-break: break-word !important;
  white-space: normal;
  font-size: 10px;
}

.notification {
  position: absolute;
  right: 0px;
  border: 1px solid;
  border-radius: 25px;
  width: 23px;
  padding: 2px;
  text-align: center;
  background-color: white;
  color: black;
  top: 0px !important;
}

.notbadge {
  position: relative;
  top: -10px;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  /* border-radius: 0.25rem; */
  border: 1px solid;
  border-radius: 25px;
  background-color: white;
  color: black;
}

.rwt__tabpanel {
  width: 100%;
}

.rwt__tab {
  text-align: left;
}

.doc-depo-meta-popups {
  max-width: 600px;
}

/* Customer Capsule Filters */
.capsulefiltericon {
  font-size: 15px;
  float: right;
}

.capsulefiltermain {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 0px;
  display: none;
}

.ccoverflow {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

/* #capsulefilterdiv {
  background-color: rgb(255, 255, 255);
  color: #000;
  min-height: 170px;
  width: 305px;
  text-align: center;
  position: absolute;
  right: 20px;
  z-index: 1;
  top: 85px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
} */

#capsulefilterdiv {
  background-color: rgb(255, 255, 255);
  color: #000;
  min-height: 170px;
  width: 492px;
  text-align: center;
  position: absolute;
  right: 695px;
  z-index: 999;
  top: 276px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  height: 198px;
  overflow-y: scroll;
  overflow-x: hidden;
}

#practiceProjectDic {
  background-color: rgb(255, 255, 255);
  color: #000;
  min-height: 105px;
  width: 305px;
  text-align: center;
  position: absolute;
  /* right: -14px; */
  left: 35px;
  z-index: 1;
  top: 50px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
}

.ccapplybtn {
  padding: 2px 5px 3px 5px;
  background-color: #01a982;
}

.cc_cardbg {
  background-color: #f1f1f1 !important;
  margin-bottom: 5px !important;
  border-radius: 3px !important;
}

.datepicker-custom-class {
  margin-right: 10px !important;
}

/* Seismic BriefCase */
.stickers_pos {
  position: absolute;
  top: 1px;
  right: -26px;
  z-index: 1000;
}


.stickers_text {
  writing-mode: vertical-rl;
  cursor: pointer;
}

.stickerr_hw{
  height: 60px;
  width: 17px;
  cursor: pointer;
}
.stickers_product {
  background-color: #ffc2b0;
  color: #000;
  cursor: pointer;
}

.stickers_solution {
  background-color: #afafaf;
  color: #000;
  cursor: pointer;
}

.stickers_program {
  background-color: #707072;
  color: #000;
  cursor: pointer;
}

.stickers_technical {
  background-color: #af94b5;
  color: #000;
  cursor: pointer;
}

.stickers_executive {
  background-color: #8be7e2;
  color: #000;
  cursor: pointer;
}

.stickers_sales {
  background-color: #b7b7b7;
  color: #000;
  cursor: pointer;
}

.stickers_service {
  background-color: #c0c0c0;
  color: #000;
  cursor: pointer;
}

.stickers_express {
  background-color: #b58ff3;
  color: #000;
  cursor: pointer;
}

.stickers_nugflix {
  background-color: black;
  color: #000;
  cursor: pointer;
}
.stickers_pos .nav-link{
  min-width: 46px;
  text-align: start;
}
.notepadBrief {
  background-image: url(/src/img/NewHome/ModalBg/notepad-yy.png);
  background-size: cover;
  width: 480px;
  height: 450px;
  position: absolute;
  top: 1px;
  right: 25px;
  z-index: 1000;
  background-color: white;
  box-shadow: 2px 2px 2px 2px #cccccc;
}
.stickers_pos .nav-link{
  min-width: 46px;
  text-align: start;
}
.stickers_click {
margin-top: 73px;
padding-left: 10px;
  width: 453px;
  height: 370px;
  position: absolute;
  right: 25px;
  z-index: 1000;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  /* box-shadow: 2px 2px 2px 2px #cccccc; */
}

.briefcase_header {
  border-bottom: 3px solid #01a982;
}

.briefcase_note {
  background-color: #333333;
  color: #ffffff;
}

.expressview_note {
  background-color: grey;
  color: #ffffff;
}

.expressview_btn {
  background-color: #f2f2f2;
  font-size: 12px !important;
  color: #000;
  box-shadow: 2px 2px 2px 2px #ccc;
  border: none;
  width: 120px;
}

.expressview_btn_active {
  background-color: #aeaea9;
  font-size: 12px !important;
  color: #000;
  box-shadow: 2px 2px 2px 2px #ccc;
  border: none;
  width: 120px;
}

.expressview_download {
  background-color: #01a982 !important;
  color: #fff !important;
}

.express_files_view {
  height: 250px;
  /* height: 300px; */
  overflow-y: auto;
  padding-left: 25px;
}

.goldCol {
  background-color: #ffb404;
  color: #fff;
  border-radius: 10px;
  font-size: 11px;
  text-align: center;
  width: 16px;
  margin-left: 5px;
  margin-top: 1px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.archivedDocs {
  background-color: #0408ff;
  color: #fff;
  border-radius: 10px;
  font-size: 11px;
  text-align: center;
  width: 16px;
  margin-left: 5px;
  margin-top: 1px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.personaroles {
  box-sizing: border-box;
  bottom: 0px;
  left: 0px;
  padding-left: 10px;
  right: 0px;
  top: 0px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 25px !important;
  position: relative !important;
  color: #fff !important;
  padding-right: 6px !important;
}

.searchbox_dime {
  height: 75px;
  box-shadow: 1px 1px 1px 1px #ccc;
}

.lastSearchE {
  background-color: #885be3;
  color: #fff;
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  width: 18px;
  float: left;
  /* margin-right: 3px; */
  margin-left: 3px;
  /* position: relative;
  left: 13px; */
}

.lastSearchE-archived {
  background-color: #0408ff;
  color: #fff;
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  width: 18px;
  float: left;
  /* margin-right: 3px; */
  /* position: relative;
  left: 14px; */
}

.lastSearchE-gold {
  background-color: #ffb404;
  color: #fff;
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  width: 18px;
  float: left;
  margin-left: 3px;
  /* position: relative;
  left: 15px; */
}

.last15header {
  background-color: #425563 !important;
  color: #fff;
  border: none;
}

.myprojectsbg {
  background-color: #01a982 !important;
}

.table-dropdown-item {
  display: table-row;
}

.dropdown_btn_width {
  width: 100% !important;
}

.hometopboxes {
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 35px;
  text-align: center;
}

.hometopboxesnumbers {
  font-weight: bold;
  color: #01a982;
  font-size: 12px;
}

#practicedd {
  background-color: rgb(255, 255, 255);
  color: #000;
  max-height: 250px;
  overflow-y: auto;
  min-width: 210px;
  width: 100%;
  text-align: center;
  position: absolute;
  overflow-x: hidden;
  z-index: 1;
  top: 30px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
}

.practicedd {
  position: absolute;
  top: -8px;
  display: block;
  z-index: 1000;
  right: 0px;
}

.validate-red {
  color: red;
}

.hw {
  width: max-content;
  height: 25px;
}

.header_button {
  background-color: #01a982;
}

.preview-modal {
  width: 90% !important;
  /* Occupy the 90% of the screen width */
  max-width: 80% !important;
  height: 85% !important;
}


.preview_iframe {
  height: 600px;
}

.preview-body {
  width: inherit;
  height: inherit;
}

.ndadoc {
  background-color: blue;
  color: #fff;
  border-radius: 10px;
  font-size: 11px;
  text-align: center;
  width: 16px;
  margin-left: 5px;
  margin-top: 1px;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.subcatbg {
  background: #7ff9e2 !important;
}

.fontwhite {
  color: #fff !important;
}

.catgeorybg {
  background: #0d5265 !important;
}

.clearsearch {
  height: 0px;
  width: 22px;
  border: none;
  background-color: white;
  cursor: pointer;
  position: absolute !important;
  top: -2px !important;
  right: 0 !important;
  font-size: medium;
  font-weight: bolder;
  color: black;
  padding-left: 10px;
  z-index: 999;
}

.sright {
  right: 20px !important;
  /* top: 3px !important; */
}

.homerefineright {
  right: 60px !important;
  top: 3px !important;
}

.doc-depo-container {
  height: 100%;
}

.height-90vh,
.doc-items-list {
  height: 90%;
}

.height-100vh {
  height: 100%;
}

.height-5vh {
  height: 5%;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fonts {
  font-size: 14px;
  font-weight: bolder;
  color: brown;
}

#practicedd {
  background-color: rgb(255, 255, 255);
  color: #000;
  height: 550px;
  width: 300px;
  text-align: center;
  position: absolute;
  left: 15px;
  z-index: 1;
  top: 102px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
}

.practicedd_btn {
  position: absolute;
  top: -8px;
  display: block;
  z-index: 1000;
  right: 0px;
}

.bg-imgcss_ {
  /* background: url("../img/homebg.jpg");
  background-repeat: no-repeat !important;
  min-height: 650px;
  max-height: 1200px;
  height: 100%;
  background-position: 0px -75px; */
  /* new style */
  /* background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0px; */
  /* background-image: url("../img/homebg.jpg"); */

  /* background-image: url("../img/Redesign/bg-img.jpg"); */
  background-image: url("../img/NewHome/BGI.jpg");
  height: 100vh;
  background-position: 0px -75px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.refine_text {
  color: #000 !important;
  font-weight: bold;
  text-decoration: none;
}

.search_container {
  background-image: url("../img/bg-gen.png");
}

.searchbox_adj {
  width: 100%;
  margin-right: 4px;
  /* padding-left: 2px; */
}

.searchbox_chr {
  background-color: #fff !important;
}

.search-boxes {
  border: 1px solid #606060 !important;
  height: 30px !important;
  border-radius: 0px !important;
}

.docdepotbtn {
  height: 30px;
  width: 30px;
  background-color: #fff;
  border-radius: 15px;
  line-height: 25px;
  padding-left: 5px;
  position: absolute;
  right: 48px;
}

.sowbtn {
  height: 30px;
  width: 30px;
  background-color: #fff;
  border-radius: 15px;
  line-height: 25px;
  padding-left: 3px;
  padding-top: 2px;
  position: absolute;
  right: 85px;
}

.submenubtn_main {
  height: 30px;
  width: 30px;
  background-color: #fff;
  border-radius: 15px;
  line-height: 30px;
  padding-left: 5px;
  position: absolute;
  right: 10px;
  padding-left: 9px;
  font-size: 15px;
}

.fa-bars {
  cursor: pointer;
}

.feedbackbtn {
  font-size: 12px !important;
  text-decoration: none;
  color: #0275d8 !important;
}

.feedbacktitle {
  width: 100%;
  border-radius: 5px;
}

.browsebtn {
  border-radius: 0px;
  background-color: #ff8d6d;
  color: #fff;
}

.fileuploadform {
  width: 270px !important;
}

.fileuploadbtn {
  background-color: #614767 !important;
}

.practicebtn {
  text-align: left !important;
  background: transparent !important;
  font-weight: bold !important;
}

.contributionsul li {
  text-align: left;
  list-style-type: none;
}

.contributionsul {
  text-align: left;
}

html {
  scroll-behavior: smooth;
}

.doc-sort-icon {
  font-size: 25px;
  margin-left: 8px;
}

.review-queues .rwt__tab {
  width: 50%;
  padding: 10px;
}

/* hamberger menu identifier */
#floatflash {
  display: block;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 999;
  right: 5px;
  top: 100px;
  animation: blink 2s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.projectstext {
  color: darkgray;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.doc-depo-container {
  height: 100%;
}

.height-90vh {
  height: 90%;
}

.doc-items-list {
  height: 85%;
  overflow: auto;
}

.height-100vh {
  height: 100%;
}

.height-5vh {
  height: 5%;
}

.doc-sort-icon {
  font-size: 25px;
  margin-left: 8px;
}

.tab-docs-queued .rwt__tab,
.tab-myworkspace .rwt__tab {
  padding: 10px;
}

* {
  box-sizing: border-box;
}

/* Style the tab */
.tab {
  float: left;
  /* border: 1px solid #ccc;
  background-color: #f1f1f1; */
  width: 13.5%;
  /* Commented and adding code to have a scroll for tabs in document analysis  */
  /* height: 50px; */
  overflow-y: auto;
  max-height: 70vh;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 10px 10px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current "tab button" class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  float: left;
  width: 85%;
  border-left: none;
  height: 600px;
}

.action-container {
  height: 9%;
}

.doc-analysis #vertical-tab-assigned-tab,
.doc-analysis #vertical-tab-inprogress-tab {
  font-size: 14px;
  padding: 12px;
}

.rwt__tab[aria-selected="true"] {
  position: relative;
  /* background: #01a982; */
  color: #01a982;
}

.tablinks.Selected {
  position: relative;
  /* background: #01a982; */
  color: #01a982;
}

.rwt__tab:focus:not(:focus-visible) {
  outline: 0;
  /* background: #01a982; */
  color: #01a982;
}

.contactIcon {
  /* color: grey;
  / font-size: 20px;
  margin-top: 3px; /

  font-size: 17px;
  margin-top: 6px;
  float: right;
  margin-right: 0px; */
  position: absolute;
  right: 68px;
  width: 29px;
  height: 26px;
  margin-top: 2px;
}

.contact-modal {
  max-width: 50%;
  height: 85%;
}
.summary-Modal {
  height: 82vh;
}
.modal-90w {
  width: 90%;
}

.modal-90w>div>div>button>span {
  color: #000;
  font-weight: bold;
  font-size: 25px;
  background: yellow;
  padding: 0px 3px 0px 3px;
}

.prescriptive-modal {
  max-width: 90%;
  max-height: 75%;
}

.perspective-table-header {
  background-color: #25ccc2 !important;
  color: white;
  font-family: Helvetica, Arial, "sans-serif";
  font-size: 14px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.rrm-vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.summary-table {
  width: 300px;
  max-width: 300px;
}

.summary-table-header {
  background-color: #25ccc2 !important;
  color: white;
  font-family: Helvetica, Arial, "sans-serif";
  font-size: 14px;
}

.summary-table-item {
  padding-left: 0px;
}

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* .tooltip-inner {
/* >>>Hamburgur Tooltip - Home Page>>>>> */
/* #tooltip1 .tooltip-inner {
  display: inline-block;
  position: relative;
  border: 1px solid #777777;
  text-decoration: none;
  border-radius: 2px;
  padding: 10px;
  margin-top: 10px;
  opacity: 1;
  animation: fade 5s linear;
  background: black;
}
#tooltip1 .tooltip-inner:before {
  content: "";
  display: block;
  position: absolute;
  left: 160px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: black;
}

#tooltip1 .tooltip-inner:after {
  content: "";
  display: block;
  position: absolute;
  left: 160px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: black;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
} */
/* Revamped Inner Page */
/* .genericfield {
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  border-bottom-color: #fff;
  color: #fff !important;
} */
/*Boot Strap*/
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append,
.input-group-prepend {
  display: flex;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-weight: bold;
  line-height: 1;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 210px;
  font-size: 12px;
}

.w-47 {
  width: 45% !important;
  display: inline-block !important;
}

.disp_inline {
  display: inline !important;
}

.plusIcon {
  font-size: 20px;
  font-weight: bolder;
  color: #777777;
  margin-left: 2px;
}

.removeIcon {
  font-size: 20px;
  font-weight: bolder;
  color: red;
  margin-left: 2px;
}

.optionListContainer {
  z-index: 2000 !important;
}

.service-btn {
  position: fixed;
  left: -17px;
  top: 245px;
  transform: rotateZ(-90deg);
  border-radius: 0px !important;
  background-color: #01a982;
  padding: 3px;
  z-index: 999;
}

.practice-modal-body {
  position: relative;
  flex: 1 1 auto;
  max-height: 80vh;
  padding: 10px;
  overflow: auto;
}

.practice-popover-content {
  /* height: 350px; */
  max-height: 350px;
  overflow: auto;
}

.vl {
  border-left: 2px solid #8c8c8c;
  height: 920px;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
}

/* .input-group-append .search-wrapper{
  border: 0px !important;
} */
.chip {
  background: #01a982 !important;
}

.multiselectormetadata {
  flex-grow: 1;
}

.griddle-previous-button {
  width: 63px;
  height: 23px;
}

.griddle-next-button {
  width: 63px;
  height: 23px;
}

.griddle-page-select {
  height: 23px;
  margin-right: 2px;
  margin-left: 2px;
}

.chip {
  background: #01a982 !important;
}

.ml1-8 {
  /* margin-left: 0.5rem !important; */
  margin-left: 7px !important;
}

.mtop2 {
  margin-top: 0.2rem !important;
}

.home_Icon {
  font-size: medium;
  margin-right: 10px;
  margin-top: 3px;
  position: absolute;
  right: -5px;
  cursor: pointer;
}

.helptext1 {
  position: absolute;
  font-size: medium;
  right: 18px;
  bottom: 10px;
}

.Home_logo {
  margin-right: 10px;
  cursor: pointer;
  color: #0d5265 !important;
}

.datechoose {
  margin-top: 0.5px;
  height: 28px;
}

.pending-approval {
  width: 158px;
}

.alignment-pending-approve {
  width: 406px;
}

.sow-preview iframe {
  /* position: absolute; */
  /* top: 0; */
  left: 0;
  width: 100%;
  height: 100%;
}

.sow-preview {
  height: 58vh;
}

.sow-preview-cp iframe {
  /* position: absolute; */
  /* top: 0; */
  left: 0;
  width: 100%;
  height: 100%;
}

.sow-preview-cp {
  height: 100%;
  width: 100%;
}

.info-button {
  background-color: #7630ea !important;
}

.action-button {
  background-color: #01a982 !important;
}

.prev-next-btn-size {
  font-size: 1rem !important;
  padding: 0.3em 0.5rem;
  width: 12rem;
  color: white !important;
}

.send-resource-request-btn-size {
  font-size: 1rem !important;
  padding: 0.3em 0.5rem;
  width: 15rem;
  color: white !important;
}

.sow-titleline {
  border-top: 1px solid #c140ff !important;
}

.sow-sectionline {
  border-bottom: 1px solid #c140ff !important;
  padding-top: 5px;
  padding-bottom: 8px;
  user-select: none;
}

.nav-link.active {
  background-color: #ffffff !important;
  color: #12b790 !important;
  border-bottom: 2px solid #742dea;
  border-radius: 0px !important;
  text-align: center;
}

.sow-container {
  max-height: 58vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 5px;
}

.designbuttton {
  background-color: white;
  border: 2px solid #01a982 !important;
  width: 400px;
  height: 500px;
  position: absolute;
  top: 37%;
  left: 40%;
  z-index: 9999;
  align-items: center;
  text-align: center;
  padding: 5%;
}

.dialog {
  border: 2px solid #01a982 !important;
}

.titlealign {
  font-size: 20px;
  font-weight: bold;
}

.textalign {
  font-size: 10px;
}

.sow-preview-container {
  max-height: 58vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 5px;
}

.sow-tab {
  border-color: #fff #fff #00a982 !important;
}

.sow-table-header {
  background-color: #25ccc2 !important;
  color: white !important;
  font-family: Helvetica, Arial, "sans-serif";
  font-size: 14px;
}

.sow-table-header--resource-type {
  width: 180px;
}

.custom-form-check-label {
  padding-left: 0;
}

.ndatoast {
  color: black;
}

.borderfix {
  border: 1px solid #ccc;
  margin-left: 0px !important;
  width: 278px;
}

.cbborderfix {
  width: 289px;
  
}

.bborderfix {
  width: 278px;
  margin-left: 0px !important;
}

.type_of_work_selected {
  color: #01a982;
}

.slider_header {
  border-bottom: 3px solid #01a982;
}

.slider_pos {
  position: absolute;
  left: 0px;
  z-index: 100000;
}

.slider_pos_right {
  position: absolute;
  right: 0px;
  z-index: 100000;
}

.slider_text {
  writing-mode: vertical-rl;
}

.slider-pop-left {
  background-color: #0d5265;
  color: #fff;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px;
}

.slider_text {
  writing-mode: vertical-rl;
}

.slider-pop {
  background-color: #0d5265;
  color: #fff;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px;
}

.slider-pop-right {
  background-color: #0d5265;
  color: #fff;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5px;
}

.submodule_header {
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: 300;
  background-color: #f1f1f1;
}

.module_selection_grid {
  display: grid;
  height: calc(100% - 4.5em);
  grid-template-columns: 1fr 1fr;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.clear_background {
  background-color: transparent !important;
}

.grid_modules {
  height: 100%;
  overflow-y: auto;
}

.submodule_label {
  font-size: 14px;
}

.over_flow {
  overflow-y: auto;
}

.hpe-green-background {
  background-color: #01a982 !important;
  color: white;
}

.grid_selection {
  display: grid;
  grid-template-rows: 390px 35px;
  grid-row-gap: 1em;
  height: 100%;
}

.dropdown_icon_width {
  width: 15px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.close_button_modules_popup {
  font-size: 2rem;
  /* margin: 5px 8px 0 0; */
  position: absolute;
  float: none !important;
  top: 1px;
  right: 10px;
  padding: 10px;
  z-index: 100000;
}

.module_slider_click {
  width: 900px;
  height: 510px;
  position: absolute;
  top: 6.5em;
  left: 28px;
  z-index: 10000;
  background-color: white;
  box-shadow: 2px 2px 2px 2px #cccccc;
  border: 1px solid #ccc;
}

.slider_click {
  width: 600px;
  height: 450px;
  position: absolute;
  left: 28px;
  top: 6.5em;
  z-index: 10000;
  background-color: white;
  box-shadow: 2px 2px 2px 2px #cccccc;
  border: 1px solid #ccc;
}

.slider_click_right {
  width: 600px;
  height: 450px;
  position: absolute;
  right: 28px;
  top: 6.5em;
  z-index: 10000;
  background-color: white;
  box-shadow: 2px 2px 2px 2px #cccccc;
  border: 1px solid #ccc;
}

.slider_content {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.slider_contentheader {
  background: aliceblue;
  border: 1px solid #5bc7ae;
}

.custombutton {
  border: 1px solid black;
  background-color: white;
  border-radius: 0;
  margin-right: 10px;
  font-weight: bold;
}

.card-body {
  padding: 0px !important;
}

/* css for Service Credits */
.service-credits-box-active {
  background-color: #01bb8f;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border: solid 1px #000 !important;
}

.service-credits-box-converted {
  background-color: #71e5d7;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border: solid 1px #000 !important;
}

.service-credits-box-purchased {
  background-color: #147c98;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border: solid 1px #000 !important;
}

.service-credits-box-delivered {
  background-color: #617d79;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border: solid 1px #000 !important;
}

.service-credits-box-expired {
  background-color: skyblue;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border: solid 1px #000 !important;
}

.exp-margin {
  margin-left: 20% !important;
  width: 50% !important;
}

.float-left {
  float: left;
}

.custom-width {
  width: 200px;
}

.helptextservice {
  float: left !important;
  color: green;
  margin-top: 2px;
  margin-left: 2px;
  font-size: 14px;
}

h3 {
  font-size: 16px;
}

.disclaimertooltip {
  padding: 3px;
  color: #000;
  font-size: 16px;
  font-weight: normal;
}

.cviewloader {
  height: 50px !important;
  position: relative;
  top: 50%;
}

.page-layout-sow {
  height: calc(100vh - 75px);
  display: grid;
  grid-template-columns: 5% auto 5%;
}

.my-form-container {
  /* max-width: 1140px; */
  /* height: 100%; */
  display: grid;
  grid-template-rows: 7em 4em 0.8em auto 5em;
  height: calc(100vh - 75px);
  /* margin: auto; */
}

.my-row {
  margin-left: 0;
  width: 100%;
  margin-right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.sow-routes-container {
  overflow: auto;
  overflow-x: hidden;
}

.buttons-control-sow {
  width: 130px;
  padding: 0.5em 1em;
}

.dd-wrapper {
  width: 100%;
}

.dd-searchbar:focus {
  border-bottom: none !important;
  box-shadow: 0 -2px 2px 0.2rem rgb(0 123 255 / 25%);
}

.my-custom-dropdown {
  max-height: 300px;
  width: inherit;
  position: absolute;
  z-index: 999;
  border: 1px solid #80bdff;
  background-color: #fff;
  overflow-y: auto;
  box-shadow: 0 4px 2px 0.1rem rgb(0 123 255 / 25%);
}

.dd-element {
  padding: 0.25em 0.5em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: context-menu;
}

.dd-element:hover {
  background-color: #01bb8f;
  color: #fff;
}

.aligntextnda {
  margin-left: 400px;
}

.myTabs {
  height: 40px;
}

.submit-prev-btn {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.yellow-button {
  background-color: #dea700 !important;
}

/* Css for the Feedback Modal and Form */
.feedbackbtn {
  height: 30px;
  width: 30px;
  background-color: #fff;
  border-radius: 15px;
  line-height: 25px;
  position: fixed;
  padding: 3px 7px 0 5px;
  right: 23px !important;
  z-index: 999 !important;
  bottom: 150px !important;
}

.feedbackModal {
  width: 70vw !important;
  max-width: 70vw !important;
}

.feedbacktooltip>.tooltip-inner {
  max-width: 300px;
  text-align: left;
  background-color: #1c181d;
  font-size: 12px;
}
/* SS- 16th Dec 24 Commented as the size was reducing on focus */
/* .custom-feedback-field,
.custom-feedback-field:focus {
  border: 1px solid #af94b5 !important;
  border-left: 3px solid #af94b5 !important;
  border-radius: 5px !important;
  font-size: .75rem !important;
} */

.custom-feedbackNote {
  /* color: lightcoral; */
  font-style: italic;
  color: red;
}

.custom-feedback-attachment {
  color: darkslategray;
  width: max-content !important;
  font-size: 1vw;
}

/* .custom-feedbacktitle {
  color: teal;
  font-size: 18px;
  margin-top: 6px;
  position: fixed; 
  top: 60px;
  left: 50%; 
  transform: "translateX(-50%)";
  text-align: center;
} */

.custom-feedback-field::placeholder {
  font-size: 12px;
  color: #495057 !important;
}

.custom-feedbackClose {
  background-color: lightcoral;
  border: 1px solid #ccc;
}

.custom-feedbackClose:hover {
  background-color: red;
  border: 1px solid lightcoral;
}

.errorMsg {
  color: crimson;
  font-size: 2vh !important;
}
.infoMsg {
  font-size: 2vh !important;
}
.red {
  color: red;
  font-size: 14px;
}

.feedbackIcon {
  margin-right: 15px;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

#goog-gt-tt {
  display: none;
}

div#LanguageSelectionModal .modal-dialog {
  max-width: 265px !important;
  /* top: 20%; */
  margin-right: 0px !important;
  top: 25px;
}

.goog-tooltip {
  display: none !important;
}

.goog-tooltip:hover {
  display: none !important;
}

.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

/* history modal css */
.history-content {
  border: 2px solid #5bc7ae !important;
  margin: 0.5em 0.2em;
}

.editIcon {
  margin: 1em;
  margin-left: 0rem;
  font-size: 12px;
  cursor: pointer;
}

.copyIcon {
  margin: 1em;
  font-size: 12px;
  cursor: pointer;
}

.childCopyIcon {
  font-size: 12px;
  cursor: pointer;
  margin-left: 4em;
}

.addIcon {
  margin: 1em;
  font-size: 12px;
  cursor: pointer;
}

.text-font {
  font-size: 10px;
  min-height: 48px;
}

.form-history-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.09rem solid #950de1;
  border-radius: 0.25rem;
}

.form-history-control:focus-visible {
  outline: none;
}

.history-panel-icon-size {
  font-size: 0.85rem;
}

.new-btn-success1 {
  background-color: #009a82 !important;
}

.new-btn-success1:disabled {
  background-color: #b3b3b3 !important;
  border: none !important;
  cursor: no-drop;
}

.sow-table-header-button {
  background-color: white;
  color: #009a82;
  border-color: #009a82;
}

.sow-table-header-button:active {
  background-color: #009a82 !important;
  color: #fff !important;
  border-color: #fff !important;
}

.sow-table-header-button:hover {
  background-color: #009a82 !important;
  color: #fff !important;
  border-color: #fff !important;
}

.sow-table-header-button:disabled {
  background-color: #b3b3b3 !important;
  border: none !important;
  cursor: no-drop;
}

.new-sow-titleline {
  margin-top: 10px;
}

.sow-fixed-icon-width {
  width: 42px;
}

.child-history-content {
  margin: 1em 1em 1em 4em;
}

#BtnSavePreference {
  background: #0d5265;
  border-radius: 0.25rem;
  border: 1px solid darkgreen;
  float: right;
  padding: 2px;
  width: 80px;
  margin-top: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}

#BtnSavePreference:disabled {
  background: #dddddd;
  border: 1px solid #dddddd;
}

#ClearBtnSavePreference {
  position: absolute;
  background: #fa9595;
  border-radius: 0.25rem;
  border: 1px solid #fa9595;
  padding: 2px;
  width: 80px;
  margin-top: 5px;
  margin-left: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  right: 100px;
}

.lang-item div,
.lang-item-selected div {
  padding: 4px;
}

.lang-item .indicator {
  display: none;
}

.lang-item-selected .indicator {
  display: auto;
}

.lang-item-selected .text {
  padding-left: 4px;
  padding-right: 4px;
}

.lang-item,
.lang-item-selected {
  text-decoration: none;
}

.lang-item div,
.lang-item:link div,
.lang-item:visited div,
.lang-item:active div {
  color: #000;
  background: #ffffff;
}

.lang-item:hover div,
.lang-item:hover a {
  color: #ffffff;
  background: #32dac8;
  text-decoration: none;
}

.lang-item:hover .lang-item:hover,
.lang-item:visited,
.lang-item:link,
.lang-item:active {
  text-decoration: none;
}

.lang-item-selected div,
.lang-item-selected:link div,
.lang-item-selected:visited div,
.lang-item-selected:hover div,
.lang-item-selected:active div {
  color: #00a982;
  font-weight: bold;
}

.sow-history-search {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1070;
  width: 563px;
  /* padding-bottom: 1.5em; */
  background: white;
}

.sow-page-button {
  font-size: 0.77rem;
}

.btn-size-color {
  width: 65px;
  background: #01a982;
  color: #fff;
  padding: 0.5em 0em !important;
}

.btn-size-color:hover {
  background: #04795e;
  color: #fff;
}

.chat_container .widget {
  display: none;
  width: 350px;
  right: 15px;
  height: 500px;
  bottom: 5%;
  position: fixed;
  background: #fff;
  /* border-radius: 10px 10px 10px 10px; */
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.16), 0 0px 5px 0 #00000096;
}

.chat_container .chat_header {
  height: 55px;
  /* border-radius: 10px 10px 0px 0px; */
  padding: 5px;
  font-size: 20px;
  background-color: #f4f7f9;
  border-bottom: 1px solid #808080;
}

.chat_container .logo {
  float: left;
  height: 40px;
  width: 100px;
}

.chat_container .chat_header_title {
  color: #3bb190;
  float: left;
  margin-top: 5%;
  margin-left: 15%;
  font-size: 18px;
  font-family: Arial;
  font-weight: bold;
}

.chat_container .chats {
  /* display: none; */
  height: 385px;
  padding: 2px;
  border-radius: 0px;
  overflow-y: scroll;
  margin-top: 1px;
  transition: 0.2s;
}

.chat_container div.chats::-webkit-scrollbar {
  overflow-y: auto;
  width: 5px;
  /* remove scrollbar space / */
  background: #3bb190;
  /* / optional: just make scrollbar invisible */
}

.chat_container .clearfix {
  margin-top: 2px;
  margin-bottom: 2px;
}

.chat_container .botAvatar {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  float: left;
  margin-left: 5px;
  /* border: 2px solid  #060DCF ; */
}

.chat_container .botMsg {
  float: left;
  margin-top: 5px;
  background: #f4f7f9;
  color: black;
  /* box-shadow: 2px 3px 9px 0px #9a82842e; */
  margin-left: 0.5em;
  padding: 10px;
  border-radius: 1.5em;
  max-width: 60%;
  min-width: 25%;
  font-family: Arial;
  font-size: 13px;
  word-wrap: break-word;
  border-radius: 5px 20px 20px 20px;
}

.chat_container div.chats>pre {
  font-family: Arial;
  font-size: 1em;
  margin: 0 !important;
  /* display: inline !important; */
  white-space: initial !important;
}

.chat_container div.chats>ul:not(.browser-default) {
  padding-left: 1em;
}

.chat_container div.chats>ul:not(.browser-default)>li {
  list-style-type: initial;
}

.chat_container div.chats>span.botMsg>ol {
  padding-left: 1em;
}

.chat_container textarea {
  box-shadow: none;
  resize: none;
  outline: none;
  overflow: auto;
  min-height: 50px;
  height: 2.5rem;
}

.chat_container textarea::-webkit-input-placeholder {
  background-color: white;
}

.chat_container textarea-webkit-scrollbar {
  width: 0 !important;
}

.chat_container .userMsg {
  animation: animateElement linear 0.2s;
  animation-iteration-count: 1;
  margin-top: 5px;
  margin-bottom: 5px;
  word-wrap: break-word;
  padding: 10px;
  float: right;
  margin-right: 0.3em;
  background: #f4f7f9;
  color: #000000;
  /* margin-bottom: 0.3em; */
  font-family: Arial;
  font-size: 13px;
  max-width: 65%;
  min-width: 15%;
  border-radius: 20px 5px 20px 20px;
  /* box-shadow: 0px 2px 5px 0px #9a828454; */
}

.chat_container blockquote {
  margin: 20px 0;
  padding-left: 1.5rem;
  border-left: 5px solid #ee6e73;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}

.chat_container .userAvatar {
  animation: animateElement linear 0.3s;
  animation-iteration-count: 1;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  float: right;
  margin-right: 5px;
  /* border: 2px solid  #060DCF ; */
}

.chat_container .usrInput {
  padding: 0.5em;
  width: 85%;
  /* margin-left: 4%; */
  border: 0;
  /* padding-left: 15px; */
  min-height: 25px;
  background-color: #ffffff;
}

.chat_container .keypad {
  /* display: none;  */
  background: #f4f7f9;
  height: 50px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 5px;
  border-radius: 0px 0px 10px 10px;
  border-top: 1px solid #e3e7ee;
}

.chat_container #sendButton {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  float: right;
  margin-right: 25px;
  text-align: center;
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
  color: #3bb190;
}

.chat_container .imgProfile {
  /* box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.16), 0 0px 10px 0 #00000096; */
  border-radius: 10px;
  width: 35%;
  cursor: pointer;
  float: right;
}

.chat_container .profile_div {
  /* display: none; */
  position: fixed;
  padding: 10px;
  width: 10%;
  bottom: 16%;
  right: 0;
  /* cursor: pointer; */
  /* color: #3BB190; */
}

/* css for dropdown menu of the bot */
.chat_container #close,
#restart,
#clear {
  cursor: pointer;
}

.chat_container .dropdown-trigger {
  cursor: pointer;
  color: #3bb190;
  margin-right: 5px;
  float: right;
  margin-top: 3%;
}

.chat_container .dropdown-content li>a,
.chat_container .dropdown-content li>span {
  color: #3bb190;
}

@keyframes animateElement {
  0% {
    opacity: 0;
    transform: translate(0px, 10px);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

/*========== css related to chats elements ============= */
/* css for card details of carousel cards */
.chat_container .content {
  transition: 0.2s;
  display: none;
  width: 350px;
  right: 15px;
  height: 500px;
  bottom: 5%;
  position: fixed;
  background: white;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 2px 10px 1px #b5b5b5;
}

.chat_container .content_header {
  object-fit: cover;
  width: 100%;
  height: 40%;
  border-radius: 10px 10px 0px 0px;
}

.chat_container .content_data {
  overflow-y: scroll;
  height: 70%;
  position: absolute;
  left: 0px;
  width: 100%;
  top: 30%;
  z-index: 1000;
  border-radius: 10px;
  background: white;
  padding: 5px;
  box-shadow: 0px -1px 20px 3px #9c9393ba;
}

.chat_container .content_title {
  color: black;
  font-weight: 600;
  word-wrap: break-word;
  padding-left: 5px;
  font-size: 1.2em;
  width: 80%;
  border-radius: 0.28571429rem;
}

.chat_container .votes {
  font-size: 12px;
  color: lightslategray;
}

.chat_container .ratings {
  margin-top: 5px;
  background: #9acd32;
  padding: 5px;
  color: white;
  border-radius: 5px;
}

.chat_container .user_ratings {
  border-radius: 0.28571429rem;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}

.chat_container .total_ratings {
  font-size: 12px;
  opacity: 0.5;
  margin-left: 5px;
}

.chat_container .content_data>.row .col {
  padding: 5px;
}

.chat_container .metadata_1,
.chat_container .metadata_2,
.chat_container .metadata_3,
.chat_container .metadata_4,
.chat_container .row {
  margin-bottom: 0px;
}

.chat_container .metadata_1 {
  color: lightslategrey;
  padding: 5px;
}

.chat_container .order,
.chat_container #closeContents {
  color: #060dcfc7;
}

.chat_container .metadata_2,
.chat_container .metadata_3,
.chat_container .metadata_4,
.chat_container .metadata_5,
.chat_container .metadata_6 {
  color: lightslategrey;
  padding: 5px;
}

.chat_container .average_cost,
.chat_container .timings,
.chat_container .location,
.chat_container .cuisines {
  width: 70%;
  float: right;
  margin-right: 25%;
}

.fa .fa-user-o {
  font-size: 15px;
}

.chat_container .stars-outer {
  display: inline-block;
  position: relative;
  font-family: FontAwesome;
}

.chat_container .stars-outer::before {
  content: "\f006 \f006 \f006 \f006 \f006";
}

.chat_container .stars-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.chat_container .stars-inner::before {
  content: "\f005 \f005 \f005 \f005 \f005";
  color: #f8ce0b;
}

.chat_container div.content::-webkit-scrollbar {
  width: 0 !important;
}

.chat_container div.content_data::-webkit-scrollbar {
  overflow-y: hidden;
  width: 0px;
  /* remove scrollbar space /
    background: transparent;
    / optional: just make scrollbar invisible */
}

/* css for single card */
.chat_container .singleCard {
  padding-left: 10%;
  padding-right: 10px;
}

/* css for image card */
.chat_container .imgcard {
  object-fit: cover;
  width: 80%;
  height: 50%;
  border-radius: 10px;
  margin-left: 1%;
}

/* css for markdown images */
.chat_container .imgcard_mrkdwn {
  width: 80%;
  border-radius: 10px;
  margin-left: 1%;
}

/* css for suggestions buttons */
.chat_container .suggestions {
  padding: 5px;
  width: 80%;
  border-radius: 0px;
  background: #ffffff;
  box-shadow: 2px 5px 5px 1px #dbdade;
}

.chat_container .menuTitle {
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.chat_container .menu {
  padding: 5px;
}

.chat_container .menuChips {
  display: block;
  background: #3bb190;
  color: #fff;
  text-align: center;
  padding: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 0px;
  font-size: 13px;
  word-wrap: break-word;
}

/* cards carousels */
.chat_container .cards {
  display: none;
  position: relative;
  max-width: 300px;
}

.chat_container .cards_scroller {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  height: 210px;
  width: 300px;
  transition: width 0.5s ease;
  margin-left: 5px;
  /* Enable Safari touch scrolling physics which is needed for scroll snap */
  -webkit-overflow-scrolling: touch;
}

.chat_container .cards_scroller img {
  border-radius: 10px;
}

.chat_container .cards div.note {
  position: absolute;
  /* vertically align center */
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  position: absolute;
  font-size: 4em;
  color: white;
}

.chat_container .cards .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  width: 30px;
  border-radius: 2px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 1;
}

.chat_container .cards .arrow.next {
  display: none;
  font-size: 2em;
  color: #ffffff;
  right: 10px;
}

.chat_container .cards .arrow.prev {
  display: none;
  font-size: 2em;
  color: #ffffff;
  left: 10px;
}

.chat_container .cards_scroller::-webkit-scrollbar {
  width: 0 !important;
}

.chat_container .cards_scroller>div.carousel_cards {
  margin: 5px;
  scroll-snap-align: center;
  position: relative;
  scroll-behavior: smooth;
}

.chat_container .cards_scroller div.carousel_cards {
  min-width: 50%;
  min-height: 70%;
  background: #b5b5b5;
  border-radius: 10px;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-10px);
    -moz-transform: translatex(-10px);
    -o-transform: translatex(-10px);
    transform: translatex(-10px);
  }

  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}

.chat_container .in-left {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.8s;
  -moz-animation-duration: 0.8s;
  -o-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  -o-animation-duration: 0.8s;
  animation-delay: 0.8s;
}

/* css for cards within the cards carousels */
.chat_container .cardBackgroundImage {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.chat_container .cardFooter {
  background: rgba(56, 53, 60, 0.86);
  border-radius: 0px 0px 5px 5px;
  position: absolute;
  z-index: 3;
  color: white;
  bottom: 0;
  width: 100%;
  height: 30%;
  word-wrap: break-word;
  padding: 1px;
}

.chat_container .cardTitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
  /* height: 40%; */
  display: inline-block;
  margin-bottom: 1px;
  font-size: 15px;
  /* font-weight: 600; */
  padding: 5px;
  /* color:#060dcfc7; */
  color: #ffffff;
  cursor: pointer;
}

.chat_container .cardDescription {
  padding: 5px;
  font-size: 13px;
  color: white;
  line-height: 15px;
}

/*css for dropDown messages*/
.chat_container .dropDownMsg {
  float: left;
  margin-top: 5px;
  background: white;
  color: black;
  box-shadow: 2px 3px 9px 0px #9a82842e;
  margin-left: 0.5em;
  padding: 10px;
  border-radius: 1.5em;
  max-width: 60%;
  min-width: 25%;
  font-size: 13px;
  word-wrap: break-word;
}

.chat_container .dropDownMsg>select {
  border: 0px solid #f2f2f2;
}

/* css for quick replies */
.chat_container .quickReplies {
  padding: 5px;
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  transform: scale(0.98);
  will-change: transform;
  user-select: none;
  cursor: pointer;
  scroll-behavior: smooth;
}

.chat_container .quickReplies::-webkit-scrollbar {
  width: 0 !important;
}

.chat_container .quickReplies.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  transform: scale(1);
}

.chat_container .chip {
  background-color: white;
  border: 1px solid #060dcfc7;
  color: #060dcfc7;
  font-weight: bolder;
  text-align: center;
}

.chat_container .chip:hover {
  background-color: #060dcfc7;
  color: white;
}

/* css for charts */
.chat_container #expand:hover {
  font-size: 18px;
}

.chat_container #expand {
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal {
  /* height: 60%; */
  border-radius: 10px;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 25vh;
  width: 90%;
  border-radius: 10px 10px 10px 10px;
  margin-left: 5%;
  background: white;
  box-shadow: 2px 3px 9px 0px #9a82847a;
  margin-top: 5px;
}

/* css for collapsible */
.chat_container .collapsible {
  margin-top: 5px;
  margin-left: 5%;
  max-width: 60%;
  min-width: 25%;
  font-size: 13px;
  word-wrap: break-word;
  border-radius: 20px;
  background: white;
}

.chat_container .collapsible-body {
  padding: 15px;
}

.chat_container .collapsible-header {
  font-weight: bold;
  color: #060dcfc7;
}

.chat_container ul.collapsible>li:first-child .collapsible-header {
  border-radius: 20px 20px 0px 0px;
}

.chat_container ul.collapsible>li:last-child .collapsible-header {
  color: #060dcfc7;
  border-radius: 0px 0px 20px 20px;
}

.chat_container ul.collapsible>li:last-child .collapsible-body {
  border-bottom: none;
}

.collapsible-header {
  padding: 10px;
}

/* css for botTyping */
.botTyping {
  float: left;
  margin-top: 5px;
  background: #e3e7ee;
  color: #000000;
  /* box-shadow: 2px 3px 9px 0px #9a82847a; */
  margin-left: 0.5em;
  padding: 15px;
  border-radius: 5px 20px 20px 20px;
  max-width: 60%;
  min-width: 20%;
  word-wrap: break-word;
  border-radius: 5px 20px 20px 20px;
}

.botTyping>div {
  width: 10px;
  height: 10px;
  background-color: #3bb190;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  margin-right: 5px;
}

.botTyping .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.botTyping .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.chat_container input:focus {
  outline: none;
}

.chat_container video:focus {
  outline: none;
}

.chat_container .video-container iframe,
.chat_container .video-container object,
.chat_container .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  margin-left: 5%;
  border-radius: 10px;
  border-style: none;
}

/* Bot pop-up intro */
.chat_container .tap-target {
  color: #fff;
  background: #3bb190;
}

.chat_container .pdf_attachment {
  border: 0.5px solid #00000014;
  width: 60%;
  height: 14%;
  border-radius: 10px;
  margin-left: 10%;
  box-shadow: 2px 3px 9px 0px #9a82842e;
}

.chat_container .pdf_icon {
  border-radius: 10px 0px 0px 10px;
  height: 100%;
  font-size: 25px;
  padding: 7% !important;
  background-color: #3bb190;
  color: #ffffff;
}

.chat_container .pdf_link {
  padding: 5% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat_container .pdf_link a {
  color: #050a09fa;
}

.chat_container {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  line-height: 25px;
  position: fixed;
  padding: 3px 7px 0 5px;
  right: 23px !important;
  z-index: 999 !important;
  bottom: 150px !important;
}

.help,
.tUserhelp {
  position: absolute;
  right: 0;
  border-bottom: 2px solid #00b188;
  border-top: 2px solid #00b188;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  width: 35px;
  text-align: center;
  bottom: 150px;
  font-size: 12px;
  font-weight: 700;
  padding-left: 5px;
  border-left: 2px solid #00b188;
  z-index: 3;
  padding: 10px;
  transform: rotate(180deg);
}

.help {
  height: 115px;
  background-color: #000;
}

.custom-pdftitle {
  color: teal;
  font-size: 18px;
}

#pdf-viewer-holder {
  padding: 2px 0 16px 0;
  max-height: 100%;
  overflow: auto;
  overflow-x: hidden !important;
}

.pdf-viewer-holder {
  margin-top: -5%;
}

.deliveryTab {
  height: 115px;
  background-color: #000;
  position: absolute;
  right: 0;
  border-bottom: 2px solid #00b188;
  border-top: 2px solid #00b188;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  width: 35px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  padding-left: 5px;
  border-left: 2px solid #00b188;
  z-index: 3;
  padding: 10px;
  transform: rotate(180deg);
  top: 25%
}

.custom-deliveryDownload {
  background-color: #12b790;
  border: 1px solid #ccc;
}

.custom-deliveryDownload:hover {
  background-color: green;
  border: 1px solid lightcoral;
}

.canvas-wrapper {
  margin-bottom: 16px;
}

canvas {
  margin: 0 auto;
  display: block;
}

.chart-dropdown {
  position: fixed !important;
  right: 15px;
}

.buttons {
  padding-top: 10px;
  border-radius: 5px;
  /*background: #dfe6e9;*/
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  -webkit-animation: 1s fadeInFromBottom forwards ease-in-out;
  animation: 1s fadeInFromBottom forwards ease-in-out;
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
  opacity: 0;
}

.buttons input[type="checkbox"] {
  display: none;
}

.buttons input[type="checkbox"]:disabled+label {
  opacity: 0.5;
}

.buttons input[type="checkbox"]:checked+label {
  background: #3d5159;
  color: white;
}

.buttons input[type="checkbox"]:disabled:checked+label {
  opacity: 1;
}

.buttons input[type="checkbox"]:not(:disabled):not(:checked)+label:hover {
  background: whitesmoke;
  transform: scale(0.98);
}

.buttons input[type="checkbox"]+label {
  padding: 2px 5px 0px 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: white;
  color: #3d5159;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border: 2px solid transparent;
}

@-webkit-keyframes fadeInFromBottom {
  from {
    transform: translateY(30px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fadeInFromBottom {
  from {
    transform: translateY(30px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInFromBottomLittle {
  from {
    transform: translateY(15px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fadeInFromBottomLittle {
  from {
    transform: translateY(15px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

small {
  float: right;
  margin-top: 15px;
  text-align: right;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  color: #3d5159;
}

small.hidden {
  opacity: 0;
}

.ball {
  border-radius: 15px;
  background: #3d5159;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  position: absolute;
  top: -2px;
  right: 0;
}

.ball.hidden {
  opacity: 0;
}

.ball:hover {
  background: #33434a;
}

.feedback-main {
  position: absolute;
  width: 200px;
  text-align: center;
  padding: 10px;
  right: 20px;
  border-radius: 8px;
  background-color: #01a982;
  z-index: 99;
  top: 530px;
  min-height: 90px;
  height: auto;
}

.feedback-main .title {
  color: #fff;
  font-size: 14px !important;
  padding-top: 10px;
  overflow-wrap: break-word;
}

.fa-times-circle {
  font-size: 16px;
}

.feedback-button {
  -webkit-transform-origin: right !important;
  -moz-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  position: absolute;
  right: 0px;
  bottom: 200px;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  width: 20px;
}

.feedback-toggle-btn {
  /*background-color: #0d5265 !important;*/
  color: #fff;
}

.feedback-toggle-btn-active {
  background-color: #0d5265 !important;
  color: #fff;
}

@-webkit-keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

select.custom-PeopleSelect {
  padding: 2px !important;
  border-radius: 3px;
}

.custom-PeopleSpan {
  float: right;
}

.feedback-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.flyertool-icon {
  height: 30px;
  width: 30px;
  background-color: #fff;
  border-radius: 15px;
  line-height: 25px;
  padding-left: 2px;
  padding-top: 2px;
  position: absolute;
}

.flyer-icon {
  width: 27px;
  height: 27px;
}

.flyer-accordian {
  font-size: 15px;
  padding-top: 5px;
  user-select: none;
  padding-bottom: 5px;
  border-bottom: 1px solid !important;
}

.legend-li {
  list-style-type: none;
}

.legend-block {
  width: 100%;
  color: black;
}

.circleBase {
  border-radius: 50%;
}

.type1 {
  width: 12px;
  height: 12px;
}

.align28 {
  margin-left: 25%;
}

.chart-span {
  border: 1px solid #00a982;
  padding: 2px;
  border-radius: 10px;
  margin-right: 3px;
  margin-bottom: 3px;
  display: inline-block;
}

.char-span-color {
  color: #00a982;
}

.alignheader {
  padding: 0;
}

/* .capabilitymodal {
  width: 50vw;
  max-width: 50vw;
} */
/*Medal icons*/
.medal-count {
  position: absolute;
  right: 0;
  left: 0;
  top: -6px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 8px;
  font-weight: 700;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.medal-icon {
  position: relative;
}

/* ends Medal icons*/
.people-title {
  margin-bottom: 0;
  line-height: 0;
  text-decoration: underline;
}

.alignTitleCenter {
  text-align: center;
  margin-left: 85px;
}

.alignTitleRight {
  text-align: right;
  margin-right: 20px;
}

.relevancy-main {
  background-color: #f1f1f1;
  min-height: 60px;
  height: auto;
  width: 27%;
  -webkit-box-shadow: 0px 0px 50px 3px rgba(1, 1, 1, 0.6);
  box-shadow: 0px 0px 50px 3px rgba(1, 1, 1, 0.6);
  border-radius: 5px;
  position: fixed;
  bottom: 5px;
  right: 40px;
}

.relevancy-main:after,
.relevancy-main:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.relevancy-main:after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #f1f1f1;
  border-width: 10px;
  margin-top: -10px;
}

.relevancy-main:before {
  border-color: rgba(194, 225, 245, 0);
  border-left-color: #f1f1f1;
  border-width: 16px;
  margin-top: -16px;
}

.relevancy-inside {
  margin: 0 auto;
}

.relevancy-inside p {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
}

.relevancy-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.relevancy-floating-btn {
  width: 20px;
  height: 20px;
  background-color: #7630ea;
  border-radius: 50%;
  position: fixed;
  right: 1px;
  bottom: 20px;
  z-index: 999;
  cursor: pointer;
}

.rel-btn {
  text-align: center;
  line-height: 20px;
}

.custom-div-vertical-center>.table-responsive {
  overflow-x: hidden !important;
}

.e3t-form-elements {
  padding: 0.25rem 0rem 0.25rem 0.5rem !important;
}

.centerDiv {
  padding: 30px;
  border: 2px solid green;
  border-radius: 10px;
  text-align: center;
  background-color: white;
  left: 36%;
  position: absolute;
  margin-top: 5%;
}

.e3t-resource-table-wrapper {
  margin: 1em;
  border: 1px dashed grey;
  padding-top: 1em;
  padding-bottom: 1em;
}

.helptextCC {
  font-size: medium;
  right: 18px;
  bottom: 10px;
  color: #12a512;
}

.sow-table-header-sticky {
  position: sticky;
  top: 0px;
  z-index: 999;
}

.filterbtnsize {
  font-size: 12px !important;
}

.filterSelect {
  width: 130px;
}

.custom-docanalysis-dolist {
  width: 100%;
  word-wrap: break-word;
  table-layout: fixed;
}

.toast-container-body-sow {
  background-color: #009a82 !important;
}

.resetButton {
  margin-top: -43px;
  margin-left: 1000px;
}

.custom-insight-download {
  font-size: 12px;
  margin-top: 10px;
  margin-left: -11px;
  position: absolute;
}

.btn-collapse-section-circle {
  width: 40px;
  height: 40px;
  padding: 6px 0px;
  border-radius: 25px;
  text-align: center;
  font-size: 16px !important;
  line-height: 1.15;
  background-color: #01a982 !important;
  border: none !important;
}

/* .input-group-text-sm{
  width:30px !important;
}

.input-td
{
  float:right;
  margin-left:160px;
} */
/* Practice UI Tab panel styles start here */
/* .practice-modal-body .nav-link.active {
  font-weight: 700 !important;
}
.practice-modal-body .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #009a82 !important;
  color: #fff !important;
  border-bottom: 5px solid #0d5265 !important;
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.practice-modal-body .nav-tabs .nav-link {
  margin-bottom: -2px;
  border: 1px solid #dee2e6;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-weight: 600;
}
#tabs-1,
#model_5 h5 {
  color: #0d5265;
  font-family: Arial, Helvetica, sans-serif;
}
#tabs-1,
#model_5 h6 {
  color: #0d5265;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}
#tabs-1,
#model_5 p {
  font-size: 11pt;
  font-family: Arial, Helvetica, sans-serif;
}
.practice-modal-body .tab-pane {
  height: auto !important;
  min-height: 350px !important;
  border: 2px solid #dee2e6;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.practice-modal-body .nav-tabs {
  border-bottom: 0px;
}

#model_5 .nav-tabs {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 auto;
  padding: 0;
  max-width: 1200px;
  list-style: none;
  -ms-box-orient: horizontal;
  -ms-box-pack: center;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  border: 0;
}
#model_5 .nav .nav-item {
  position: relative;
  z-index: 1;
  display: block;
  margin: 0;
  text-align: center;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
#model_5 .nav .nav-link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 2.5;
  border: 1px solid var(--m5-border);
  border-radius: 0;
  padding: 0.65em 0 0.5em;
  background: var(--m5-tab-bg);
  color: var(--m5-tab-text);
  line-height: 1;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  outline: unset;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 0.8em;
}
#model_5 .nav .nav-link:hover {
  background-color: var(--m5-tab-hover-bg);
  color: var(--m5-tab-hover-text);
}
#model_5 .nav .nav-link.active,
#model_5 .nav .nav-link.active:hover {
  background: none;
  border-top-color: var(--m5-tab-hover-text);
  border-bottom: none;
  box-shadow: inset 0 3px 0 var(--m5-tab-hover-text);
  color: var(--m5-tab-hover-text);
}
#model_5 .nav i {
  display: block;
  margin: 0;
  font-size: 2.4em;
  line-height: 1;
  speak: none;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
/* Practice UI Tab panel styles ends here */
.timelapse {
  position: absolute;
  left: 31%;
  margin-top: 8px;
  text-decoration: none;
  font-style: italic;
  color: gray;
}
.timelapse:hover{
  color: #0d6efd;
  text-decoration: underline;
}

.createModuleBtn {
  float: right;
  margin-right: 1.5em;
  position: relative;
  bottom: 13px;
}

.parentZIndex {
  z-index: 11000 !important;
}

.inline-edit {
  z-index: 12000 !important;
}

.custom-modal {
  width: 70%;
}

.module_slider_click_width {
  width: auto !important;
  min-width: 900px;
}

.capsuleonelead {
  font-size: 12px;
  color: #007bff;
  text-transform: capitalize;
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
  /* -webkit-box-shadow: 0px 0px 50px 3px rgba(1, 1, 1, 0.6);
  box-shadow: 0px 0px 50px 3px rgba(1, 1, 1, 0.6);
  border-radius: 5px; */
}

.onelead-main span i {
  color: #007bff;
}

.rvertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.tdata-list-arrow {
  font-size: 24px;
  color: #147c98;
}

.onleadLink {
  color: #fff;
  font-weight: normal;
}

canvas {
  cursor: pointer;
}

.parentZIndex {
  z-index: 11000 !important;
}

.input-group-text-sm {
  width: 30px !important;
}

.input-td {
  float: right;
  margin-left: 160px;
}

/* default hp color codes fot toastify */
.Toastify__toast--default {
  color: white !important;
  background: #0d5265 !important;
}

.Toastify__toast--info {
  color: white !important;
  background: #14eaf2 !important;
}

.Toastify__toast--success {
  color: white !important;
  background: #00a982 !important;
}

.Toastify__toast--warning {
  color: white !important;
  background: #ff8300 !important;
}

.recharts-layer {
  cursor: pointer;
}

.recharts-pie {
  cursor: pointer;
}

.close {
  cursor: pointer;
}

.Toastify__toast--error {
  color: white !important;
  background: #d9534f !important;
}

/* css for Doc Depo table */
.tab-background {
  background-color: #614767 !important;
  color: #fff;
}

.btn-filter {
  background: none !important;
  color: #fff !important;
  border: none !important;
}

.disableScreen {
  pointer-events: none;
  opacity: 0.2;
}

.disableModal {
  pointer-events: none;
  /* opacity: 0.9; */
}

.toggle-selected-highlight {
  color: #01a982 !important;
}

.capsuleonelead-head {
  font-size: 12px;
  color: #fff;
  text-transform: capitalize;
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
}

.rcw-picker-icon {
  display: none;
}

.rcw-header {
  padding: 5px !important;
}

.rcw-header h4 {
  font-size: 14px !important;
  padding: 5px 0px !important;
}

.rcw-new-message {
  width: calc(100% - 30px) !important;
  font-size: 13px;
}

.rcw-avatar {
  width: 25px !important;
  height: 25px !important;
}

.rcw-snippet-title {
  font-size: 12px !important;
}

.user-count-card {
  margin: 10px auto;
  height: auto;
  padding-top: 10px;
  text-align: center;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: #f1f1f1;
}

.user-details {
  margin: 0px auto;
  height: auto;
  border-radius: 6px;
  text-align: center;
  font-size: 14px;
}

.detail-card {
  border: 1px solid #01a982 !important;
  height: 180px;
  padding: 10px;
}

.score-div {
  height: 100px;
  width: 100px;
  border-radius: 70px;
  border: 2px solid #7630ea !important;
  margin: 0px auto;
}

.score-div p {
  margin: 37px auto;
}

.user-details .fa {
  font-size: 18px !important;
}

.user-details p {
  font-size: 16px !important;
}

.user-details table {
  font-size: 12px;
}

.game-model-space1 {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  font-size: 14px;
}

.game-model {
  display: flex;
  flex-wrap: wrap;
  align-content: left;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  font-size: 13px;
  font-weight: bold;
  transform: rotate(180deg);
}

.game-model-spacing2 {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  font-size: 14px;
}

.game-model-space {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  font-size: 14px;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

.GameUser {
  background-color: #f7f7f7;
}

.pointsAlign {
  display: flex;
  justify-content: right;
  align-items: center;
  text-align: right;
}

.pointsAlign1 {
  display: flex;
  align-items: center;
}

.pointsAlign2 {
  display: flex;
  justify-content: center;
}

Card {
  color: white !important;
}

.fasSize {
  color: aliceblue;
}

.circle {
  display: inline-table;
  vertical-align: middle;
  width: 45px;
  height: 45px;
  background-color: #00b388;
  border-radius: 60%;
  float: right;
}

.circle-1 {
  display: inline-table;
  vertical-align: middle;
  width: 45px;
  height: 45px;
  background-color: #00b388;
  border-radius: 60%;
}

.circle__content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}

.cardBody {
  color: #fff;
  padding: 0.7rem !important;
}

hr {
  color: #dee2e6 !important;
}

.game-model-spacing2 .fa-plus {
  font-size: 26px;
  color: #263040;
}

.game-model-space1 .fa-plus {
  color: #263040;
}

.gamification_align {
  height: 110px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
}

/* gamification User dashboard  */
.circle1 {
  display: inline-table;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  background-color: #7630ea;
  border-radius: 50%;
  float: right;
}

.circle__content1 {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}

.txt-pos1 {
  position: absolute;
  top: 55px;
  right: 135px;
}

.bg-badge {
  background-color: #01a982 !important;
  color: white;
}

.usercss {
  display: flex;
}

.insidediv {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  /* height: 80px; */
}

.fontresize {
  font-size: 16px !important;
  font-weight: 600;
}

.insidedivModal {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 14px;
}

.fas_size {
  font-size: 16px;
}

.insidediv_Leaderdashboard {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 160px;
}

/* display this row with flex and use wrap (= respect columns' widths) */
.row-flex {
  display: flex;
  flex-wrap: wrap;
}

/* vertical spacing between columns */
.content {
  height: 100%;
  padding: 20px 20px 10px;
  color: #fff;
}

/* Demo backgrounds and styling*/
.colour-1 {
  background: #7630ea;
  color: #fff;
  border-radius: 0.25rem;
}

.colour-2 {
  background: #117b82;
  color: #fff;
  border-radius: 0.25rem;
}

.colour-3 {
  background: #00739d;
  color: #fff;
  border-radius: 0.25rem;
}

.colour-4 {
  background: #606b7d;
  color: #fff;
  border-radius: 0.25rem;
}

.colour-5 {
  background: #9b6310;
  color: #fff;
  border-radius: 0.25rem;
}

.colour-6 {
  background: #8d741c;
  border-radius: 0.25rem;
}

.colour-7 {
  background: #606b7d;
  border-radius: 0.25rem;
}

.marquee-main {
  background: rgba(255, 255, 255, 0.4);
  /* background-color: #fff; */
  width: 100%;
  min-height: 30px;
  height: auto;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 24%) 0px 12px 24px;
}

.custom-marquee {
  line-height: 24px;
  color: #00567a;
  font-size: 14px;
  font-weight: 600;
  padding-top: 10px;
  text-decoration: underline;
  cursor: pointer !important;
}

.carousel-caption {
  text-align: left !important;
  left: 5%;
  top: -6%;
  right: 15%;
  width: 65%;
  line-height: 25px;
}

.border-2x {
  border: 2px solid #dee2e6;
}

.gamification-title {
  font-size: 20px;
  font-weight: 500;
  color: #00567a;
  line-height: 25px;
}

.achivement-bg {
  background-color: #fff;
  border: 1px solid #009a82;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 10px 40px 0 rgb(62 57 107 / 7%), 0 2px 9px 0 rgb(62 57 107 / 6%);
}

.view-grid {
  display: grid;
}

.grid-column {
  grid-template-columns: 6% 89% 6%;
  align-items: center;
}

/* Feedback Star Rating */
#rating-details td {
  padding-bottom: 10px;
}

#rating-details .star-active {
  color: #fec901;
  margin-top: 10px;
  margin-bottom: 10px;
}

#rating-details .star-active:hover {
  color: #f9a825;
  cursor: pointer;
}

#rating-details .star-inactive {
  color: #cfd8dc;
  margin-top: 10px;
  margin-bottom: 10px;
}

#ratingdivMsg {
  background-color: rgb(255, 255, 255);
  color: #000;
  height: 130px;
  width: 300px;
  text-align: center;
  position: absolute;
  right: 25px;
  z-index: 1;
  top: 15px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
}

#eratingdivMsg {
  background-color: rgb(255, 255, 255);
  color: #000;
  height: 90px;
  width: 250px;
  text-align: center;
  position: absolute;
  right: 25px;
  z-index: 1;
  top: 15px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
}

/* .internal-user-home-search #react-autowhatever-1 {
  z-index: 999 !important;
  width: 85% !important;
  margin-left: 64px !important; */
  /* margin-right: 100px !important; */
/* } */

.bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 5px;
  margin-bottom: 1px;
}

.rating-label {
  font-weight: 600;
  font-size: 9px;
}

.rating-bar {
  width: 100px;
  margin-top: 3px;
  border-radius: 2px;
}

.bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 1px;
}

.bar-5 {
  width: 100%;
  height: 4px;
  background-color: #01a982;
  border-radius: 20px;
}

.bar-4 {
  width: 70%;
  height: 4px;
  background-color: #01a982;
  border-radius: 20px;
}

.bar-3 {
  width: 30%;
  height: 4px;
  background-color: #01a982;
  border-radius: 20px;
}

.bar-2 {
  width: 15%;
  height: 4px;
  background-color: #ffbc44;
  border-radius: 20px;
}

.bar-1 {
  width: 10%;
  height: 4px;
  background-color: #fc6161;
  border-radius: 20px;
}

#rating-details td {
  padding-bottom: 10px;
}

#rating-details .star-active {
  color: #fec901;
  margin-top: 10px;
  margin-bottom: 10px;
}

#rating-details .star-active:hover {
  color: #f9a825;
  cursor: pointer;
}

#rating-details .star-inactive {
  color: #cfd8dc;
  margin-top: 10px;
  margin-bottom: 10px;
}

#ratingdivMsg {
  background-color: rgb(255, 255, 255);
  color: #000;
  height: 100px;
  width: 240px;
  text-align: center;
  position: absolute;
  right: 25px;
  z-index: 1;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  top: -125px;
  box-shadow: 0 10px 40px 0 rgb(62 57 107 / 7%), 0 2px 9px 0 rgb(62 57 107 / 6%);
}

#react-autowhatever-1 {
  z-index: 999 !important;
}

.rcw-widget-container {
  z-index: 9 !important;
  
}

.rating-boxs {
  margin-top: 7px;
  height: 77px;
  width: 75px;
  color: #fff;
  /* background-color: #01a982; */
}

.text-right {
  font-size: 8px;
}

.rating-star {
  color: grey;
}

.list-item--active {
  background-color: aquamarine;
}

.ratingbox-green {
  background-color: #01a982;
}

.ratingbox-amber {
  background-color: #ffbc44;
}

.ratingbox-red {
  background-color: #fc6161;
}

.ratingbox-default {
  background-color: grey;
}

.asterisks {
  color: red !important;
}

.filter-remove {
  background-color: #D3D3D3;
  color: #fff;
}

.filter-remove:hover {
  background-color: #404b5c;
  color: #fff;
}


.basic .text-right {
  font-size: 12px;
}

.right-bottom-2 {
  top: 180px;
}

.user_profile_link {
  text-decoration: underline;
  cursor: pointer;
  color: white;
}

.sow-table-section-row > td{
  background-color: rgb(97, 71, 103) !important;
  color: white !important;
}

@media print {
  #sow-print-table-header th {
    background-color: #25ccc2 !important;
    color: white !important;
    font-family: Helvetica, Arial, "sans-serif";
    font-size: 14px;
  }

  #sow-print-table-section-row td {
    background-color: rgb(97, 71, 103) !important;
    color: white !important;
  }
}

/* Product Line Recommedation Css */
#other-pages:focus {
  outline: none;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.banner-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  height: 540px;
}

.demo-infos-wrap {
  background: #f4f7fa;
}

.demo-infos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  text-align: center;
}

.demo-infos .demo-info {
  padding: 30px 0;
}

.demo-infos .demo-info h4 {
  font-size: 3rem;
  font-size: 500;
  color: #246df8;
}

.demo-infos .demo-info p {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  color: #101725;
}

.section-header h2 {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 50px;
}

.page-preview {
  margin-bottom: 50px;
}

.page-preview .thumb {
  -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  border-top: 2px solid #00c781 !important;
}

.page-preview .thumb .overlay {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-preview .thumb .overlay a {
  background: #246df8;
  color: #fff;
  border-radius: 3px;
  padding: 5px 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.page-preview .thumb:hover .overlay {
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.page-preview .thumb:hover .overlay a {
  top: 50%;
}

.page-preview h3 {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}

.page-preview h3:hover a {
  text-decoration: underline;
}

.isotope-nav ul {
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
  list-style: none;
  font-size: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.isotope-nav ul li {
  font-size: 16px;
  padding: 3px 20px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 15px;
}

.isotope-nav ul li.active {
  background: rgba(36, 109, 248, 0.15);
  color: #00c781;
  font-weight: 600;
}

@media all and (max-width: 767px) {
  .pages .col-xs-12 {
    width: 50%;
  }

  .cp-nav-demo .navbar-header .navbar-toggle {
    border: 1px solid #00c781;
    border-radius: 0;
  }

  .cp-nav-demo .navbar-header .navbar-toggle .icon-bar {
    background: #00c781;
  }

  .cp-nav-demo .navbar-collapse {
    background: #fff;
  }

  .cp-nav-demo .navbar-nav .nav-button {
    padding-bottom: 20px;
  }

  .cp-nav-demo .navbar-nav .nav-button a {
    display: inline-block;
  }

  footer {
    text-align: center;
  }

  footer .credit {
    text-align: center;
  }
}

@media all and (max-width: 539px) {
  .pages .col-xs-12 {
    width: 100%;
  }
}

@media all and (max-width: 439px) {
  .demo-infos {
    display: block;
    padding-bottom: 30px;
  }

  .demo-infos .demo-info {
    padding: 30px 0 0;
  }
}

.isotope-item h6 a {
  text-decoration: underline;
}

.font_prod {
  font-weight: 600;
  font-size: 20px;
}

.homepage-featureguide {
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  right: 1em;
  top: 6%;
}

.homepage-featureguide-popup {
  height: 350px;
  top: -2.5em;
  z-index: 10000;
  background-color: white;
  box-shadow: 2px 2px 2px 2px #cccccc;
  border: 1px solid #ccc;
}

.homepage-featureguide-popup-header {
  width: 100%;
}

.homepage-featureguide_slider_content {
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
}

.homepage-featureguide_body {
  padding-left: 0;
  padding-right: 0;
}

.typeofwork_modal>.modal-dialog {
  max-width: 600px;
}

.typeofwork_modal .col-sm-3 {
  display: flex;
  align-items: center;
}

.data-loader {
  margin: 15px 0;
}

.chatbot-card {
  /* height: 90px; */
  width: 250px;
  background-color: whitesmoke;
}

/* .CardChatBot{
  height: 100px;
  width: 750px;
} */

.shadow-effect {
  background: #f5f7f8;
  /* color: #f5f7f8; */
  padding: 20px;
  border-radius: 4px;
  text-align: left;
  border: 1px solid #ececec;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.02);
  font-weight: 600;
  width: 125px;
  word-break: break-all;
  /* z-index: -1; */
}

.shadow-effect_ {
  background-color: #f4f7f9;
  border-radius: 10px;
  width: auto;
  padding: 15px;
  text-align: left;
}

.shadow-effect-title {
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
}

#shadow-effect p {
  font-family: inherit;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 0 17px 0;
  font-weight: 400;
  color: #f5f7f8;
  text-align: left;
}

/* .img-circle {
  border-radius: 50%;
  vertical-align: middle;
  max-width: 90px;
  min-height: 90px;
  transform-style: preserve-3d;
  margin: 0 auto 17px;
} */
#customer-testimonoals {
  margin-top: 6%;
}

.testimonial-name {
  /* margin: -17px auto 0; */
  display: table;
  /* width: 150px; */
  /* background: linear-gradient(100deg, #fff, #ccc); */
  /* background: #f5f7fa; */
  /* background: linear-gradient(135deg, #ff3e00, #eaa400); */
  /* margin-left: 10px; */
  padding-left: 6px;
  /* border-radius: 12px; */
  text-align: left;
  color: #000;
  border-left: 2px solid #35e65d;
  word-break: break-all;
  cursor: pointer;
  /* box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.12), 0px 5px 7px rgba(0, 0, 0, 0.5); */
}

#customer-testimonoals .item {
  background-color: #f4f7f9;
  border-radius: 10px;
  /* width: auto; */
  padding: 15px;
  text-align: left;
  /* text-align: center; */
  /* padding: 50px; */
  margin-bottom: 0px;
  opacity: 0.2;
  transform: scale3d(0.8, 0.8, 1);
  transition: all 0.3s ease-in-out;
  width: 150px;
  /* z-index: -1; */
}

#customer-testimonoals .owl-item.active.center .item {
  opacity: 2;
  transform: scale3d(1, 1, 1);
  width: 170px;
}

.owl-nav {
  display: block !important;
}

.row1 {
  width: 345px;
  height: auto;
}

/* .owl-carousel .owl-nav button.owl-prev button.owl-next{
 font-size: xx-large !important;
 color: gray;
 height: 10px;
 width: 10px;
 font-weight: 600 !important;
} */

/* .owl-carousel .owl-nav button.owl-next, */

/* max-width constrains the width of our carousel to 550, but shrinks on small devices */
.carousel__container {
  max-width: 550px !important;
  margin: auto;
}

/* gives us the illusion of a "centered" slide */
.carousel__slider {
  padding-left: 5%;
  padding-right: 5%;
  min-height: 120px;
  height: auto;
}

.testimonial-name a {
  color: #0066cc !important;
}

/* gives us the illusion of spaces between the slides */
.carousel__inner-slide {
  width: calc(100% - 20px) !important;
  height: calc(100% - 80px) !important;
  left: 10px !important;
  top: 10px !important;
}

.item_ {
  background: #f5f7f8;
}

/* .caroualItem{
  background: #f5f7f8;
  padding: 10px;
}
.activeItem{
  background-color: #f5f5f5;
  padding: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
} */
.caroualItem {
  background: #36b1ff;
  padding: 10px;
  border-radius: 10px;
  /* min-height: 90px; */
  /* height: auto; */
}

.more-info {
  background: #f5f7f8;
  padding: 10px;
  min-height: 35px;
  height: auto;
  /* line-height: 30px; */
  border-radius: 10px;
  margin-left: 8px;
  color: blue;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.activeItem {
  background-color: #29ffb4;
  padding: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px;
}

.more-info-content {
  min-height: 90px;
}

.carousalCard {
  min-height: 120px;
  height: auto;
  overflow: auto;
  /* background-color: #0066cc; */
}

.pull-right {
  float: right !important;
  text-align: right !important;
}

.positive-response {
  background-color: #00c781;
  color: #fff;
  height: 20px;
  width: 20px;
  line-height: 10px;
  border: 0;
  border-radius: 2px;
  vertical-align: middle;
}

.positive-response,
.negative-response i {
  text-align: center;
  vertical-align: middle;
  font-size: 0.6rem;
}

.positive-response:hover {
  background-color: #00c781;
  color: #fff;
}

.negative-response {
  background-color: #a2423d;
  color: #fff;
  height: 20px;
  width: 20px;
  line-height: 10px;
  border: 0;
  border-radius: 2px;
  vertical-align: middle;
}

.negative-response:hover {
  background-color: #a2423d;
  color: #fff;
}

.response-main {
  background-color: #fff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px;
  width: 260px;
}

.mindspark-card-view {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 3px;
  height: 100px;
  transition: transform .2s;
  width: var(--cardWidth);
  margin: var(--cardMargin);
}

.mindspark-card-view:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.wrongFile-btn {
  background-color: #7630ea;
  color: #fff;
  padding: 0.18rem 0.4rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  border: 0;
  width: 30%;
}

.wrongAnswer-btn {
  background-color: #00739d;
  color: #fff;
  padding: 0.18rem 0.4rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  border: 0;
  width: 39%;
}

.others-btn {
  background-color: #007a5e;
  color: #fff;
  padding: 0.18rem 0.4rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  border: 0;
  width: 26%;
}

.submit-btn {
  background-color: #28a745;
  color: #fff;
  padding: 0.18rem 0.4rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  border: 0;
}

.wrongFile-btn:hover,
.wrongAnswer-btn:hover,
.others-btn:hover,
.submit-btn:hover {
  color: #fff;
}

.wrongFile-btn-active {
  background-color: #fff;
  color: #7630ea;
  border: 2px solid #7630ea;
  cursor: pointer;
  /* padding: 0.18rem 0.4rem; */
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  width: 30%;
  margin-right: 3px;
}

.wrongAnswer-btn-active {
  background-color: #fff;
  color: #00739d;
  border: 2px solid #00739d;
  cursor: pointer;
  /* padding: 0.18rem 0.4rem; */
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  width: 39%;
  margin-right: 3px;
}

.others-btn-active {
  background-color: #fff;
  color: #007a5e;
  border: 2px solid #007a5e;
  cursor: pointer;
  /* padding: 0.18rem 0.4rem; */
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  width: 26%;
}

.prev,
.next {
  color: #000 !important;
}

.prev:hover {
  background-color: transparent !important;
  text-decoration: none;
  color: #000;
}

.next:hover {
  background-color: transparent !important;
  text-decoration: none;
  color: #000;
}

.thumbnails {
  display: none !important;
}

.carousel-caption-top {
  color: #000 !important;
  font-size: 15px !important;
  padding: 8px 0;
  width: 100%;
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;
  text-shadow: none !important;
}

.carousel-image {
  display: none !important;
}

/* .fade {
  animation-name: fade !important;
  animation-duration: 3000s !important;
  opacity: 9.5 !important;
} */

.urltext a {
  color: #0066cc;
}

.urltext {
  font-size: 13px !important;
  align-items: center !important;
  margin: 0 auto;
  font-weight: normal;
  padding-top: 0px !important;
  position: relative;
  top: -20px;
  /* text-overflow: ellipsis !important; */
  /* max-width: 90% !important; */
  /* white-space: nowrap;
  width: 800px;
  overflow: hidden;
  text-overflow: ellipsis; */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.carousel-container {
  min-height: 140px !important;
}

/* .active,
.dot:hover {
  background-color: #1A1F2B !important;
} */

.titletext {
  margin: 0 auto;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #1a1f2b;
}

.chatbot-carousel>.carousel-root>.carousel-slider {
  max-width: 320px;
  height: 180px;
  background-color: #8080801c;
  border-radius: 8px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 320px;
  max-height: 180px;
}

.chatbot-carousel>.carousel-root>.carousel .thumbs-wrapper {
  margin: 0;
}

.chatbot-carousel-title {
  margin: 0 auto;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #1a1f2b;
  font-weight: bold;
  margin-bottom: 14%;
  font-size: 14px;
}

.chatbot-carousel-url {
  font-size: 13px !important;
  align-items: center !important;
  margin: 0 auto;
  font-weight: normal;
  padding-top: 0px !important;
  position: relative;
  top: -20px;
  /* text-overflow: ellipsis !important; */
  /* max-width: 90% !important; */
  /* white-space: nowrap;
  width: 800px;
  overflow: hidden;
  text-overflow: ellipsis; */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  cursor: pointer;
  color: blue;
}

.chatbot-feedback-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.chatbot-flyer-accordian {
  font-size: 15px;
  padding-top: 5px;
  user-select: none;
  padding-bottom: 5px;
}

.chatbot-feedback-close {
  /* width: 232px; */
  display: flex;
  justify-content: end;
  padding-bottom: 4%;
}

.mandatory-input {
  border: 1px solid #ff0000;
}

.table-filter-row table th {
  vertical-align: middle !important;
}

.sow-resource-table-header {
  max-width: 80px;
  word-wrap: break-word;
  vertical-align: top !important;
}

.sow-resource-table-header-ir {
  vertical-align: top !important;
}

.sow-resource-table-header_resources {
  width: 125px;
}

.sow-resource-table-header_cost {
  width: 75px;
}

.sow-resource-table-header_notes {
  width: 50px;
}

.sow-resource-table-icons {
  width: 60px
}

.sow-table-header--skill-type {
  width: 180px;
}


.table thead th {
  vertical-align: middle;
}

.rps_details_modal_header {
  background: rgb(96, 106, 116);
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2%;
}

.skill_mapping_accordion {
  color: #007a5e;
  width: 100%;
}

.skill-mapping-error {
  color: red;
  margin: 0;
}

.custom-height {
  min-height: 160px !important;
}

.filter-list {
  background-color: #fff !important;
}

.additional_info_table th {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #dee2e6 !important;
}

.untaggedmodal-info {
  padding-top: 0px;
  margin: 3px;
  font-size: 15px;
}

.untaggedmodal-input {
  height: 29px !important;
  border-bottom-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.sow-tdna-btn {
  padding: 0.25rem 0.5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  background-color: #01a982;
  border: 0;
  margin-left: 5px;
  color: #fff;
}

.sow-tdna-btn:hover,
.sow-tdna-btn-danger:hover {
  color: #fff;
}

.sow-tdna-btn-danger {
  padding: 0.25rem 0.5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  background-color: #1A1F2B;
  border: 0;
  color: #fff;
}

.slider_pos_ir {
  position: fixed;
  left: 0px;
  z-index: 100000;
}

.slider_pos_ir_custom {
  top: 50%
}

.slider_pos_ir_predefined {
  top: 25%
}

.admin_res-skill-mapping {
  padding: 1%;
  border-bottom: 1px solid;
  margin-left: 0;
  margin-right: 0;
}

.admin_res-skill-mapping-modal>.modal-lg {
  max-width: 800px !important;
}

.admin_res-skill-mapping_modalbody {
  height: auto !important
}

.delivery-mix-modal {
  max-width: 1400px !important;
  max-height: 75% !important;
}

.doc-results-container {
  min-height: 450px !important;
  padding: 15px 30px;
  width: 1000px !important;
}

.vertical-line {
  /* border-left: 1px solid #ccc;  */
  /* height: 400px; */
  /* margin: 0 10px; */
  max-height: 90vh;
  overflow-y: auto;
  padding: 10px;
}

.custome-nav-link.active {
  background-color: #00a982 !important;
  text-align: center !important;
  color: white !important;
}
.custome-nav-link:hover {
  background-color: lightgray !important;
  text-align: center !important;
  color: black !important;
}

.custom-sidebar {
  height: 25vh;
  min-height: 250px;
  overflow: auto;
  position: sticky;
  top: 0%;
  overflow-x: hidden;
}

.custom-rightbar {
  height: 70vh;
  min-height: 250px;
  /* overflow: scroll; */
  /* Commented as we are getting double scroll */
  overflow-x: hidden;
  padding: 0px;
}

.custom-sidebar .nav-link {
  text-align: left !important;
}

/* UnComment for ITG - START */
/* .rcw-conversation-container{
  min-width: 630px !important;
  max-width: max-content !important;
}

.rcw-messages-container {
  height: 60vh !important;
  max-height: max-content !important;
} */
/* UnComment for ITG - END */
/* .page_background_image{
  background: url(/src/img/NewHome/MicrosoftTeams-image_12.png) no-repeat;
  background-size: cover !important;
  background-position: center;
  background-clip: content-box;
  background-attachment: scroll;
  max-width: 1600px;
  width: 100%;
  height: 100vh;
} */
.homesearch-autosuggest input{
  width: 100% !important;
  background-color: #fff !important;
  border: 1px solid #606060 !important;
  height: 44px !important;
  border-radius: 0px !important;
  padding-right: 30px;
  font-size: 18px;
  /* margin-left: 14.5% !important; */
  z-index: 1;
  /* position: fixed;
  top: 15%; */
}

.homesearch-autosuggest {
  display: flex;
  justify-content: center;
}
.document-search-main {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 100px;
  width: 65%;
  justify-content: center;
}

.document-search-main > .react-autosuggest__container {
  width: 70%;
}

.iframeModal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.3rem;
  outline: 0;
}
/* #content{
	height: 88.5vh;
  background-image: url("../img/NewHome/BGIM.png");
	background-position: 0 -150px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  overflow: hidden;
} */

/* #content{
  background: url(/src/img/NewHome/MicrosoftTeams-image_12.png) no-repeat;
  background-size: cover !important;
  background-position: center;
  background-clip: content-box;
  background-attachment: scroll;
  max-width: 1600px;
  width: 100%;
  height: 100vh;
} */
/* .content-container{
  background:  url(/src/img/NewHome/DeltaHomeNew.png) no-repeat;
  background-size: cover;
  background-position-y: bottom;
  background-attachment:inherit;
  width: 100%;
  height:95vh;
} */

.content-container {
  position: static;
}
.searchbox{
	/* position: absolute; */
	top: 20px;
	height: 250px;
  left: 30px;
}
.hamburger{
	height: 32px;
	width: 32px;
	background-color: #FFF;
	border: 1px solid #000;
	border-radius: 15px;
	margin-left: 20px;
	position: absolute;
	top: 10px;
}
.searchpanel{
	border-radius: 5px;
	border: 1px solid #ccc;
	/* background-image: url("../img/Redesign/box-bg.png"); */
}

.boxtext1{
	font-size: 25px;
    font-weight: bold;
	padding-right: 15px;
	padding-top: 15px;
}
.anpslinksbtn{
	background-color: #D9D9D9;
	color: #000;
	text-align: center;
	font-weight: bold;
	position: absolute;
	bottom: -70px;
	left: 550px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
.anpslinks{
	background-color: #D9D9D9;
}
.copy{
	background-color: #858383;
}
.navlink-items{
  font-weight: 400;
  font-size: 14px;
  color: #000 !important;
  padding-left: 3px;
  padding-right: 3px;
  /* margin-left: 90px; */
}
.custom-navbar{
  padding: 0px 8px; 
}
.custom-list {
  list-style-type: none;
}
.space-inlogo{
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 8px;
}
.clicked{
  border-color: transparent !important;
  background-color: yellow;
  border: none;
}

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* font-family: Arial, Helvetica, sans-serif !important; */
}

.navbar-centered {
  z-index: 11;
  justify-content: space-between;
  height: 100% !important;
}

.title {
  font-size: 1rem;
  margin-left: 10px;
}
.nav-icon {
  margin:0 15px;
  width: 20px;
  height: 20px;
  margin-top: 8px;
}
/* .logo {
  font-size: 1rem;
} */
.custom-nav-link {
  margin: 0 40px;
  /* font-size: 0.90rem; */
}
.custom-notbadge {
  position: relative;
  top: -3px;
  display: inline-block;
  padding: 0.5em 0.7em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  /* vertical-align: baseline; */
  border-radius: 0.25rem;
  border: 1px solid;
  border-radius: 25px;
  background-color: #4DDDBB;
  color: white;
  right: 1rem;
}
.text-with-lines {
  position: relative;
  text-align: center;
  background-color: white;
  height: 30px;
}

.text_c {
  background-color: white;
  padding: 0 2px;
  z-index: 1; /* Ensure the text is above the line */
}

.line_c {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  height: 2px;
  width: 50%;
  background-color: white;
}
.dropdown-menu .dropdown-menu-header {
  padding: 0.7rem 1rem;
  margin-bottom: 0.5rem;
  background: #eff1f5;
  font-size: .85rem;
  font-weight: 700;
}
.clearall {
  padding: 10px;
  font-size: medium;
}
.booknmark{
 margin-left: -150px !important
}
.custom-new{
  margin-left: -100px !important
}
.custom-last15{
  margin-left: -20px !important
}
.custom-footer{
  position: relative;
  bottom: 10;
  width: 100%;
  background-color: #f0f0f0; /* Set your desired background color */
  padding: 20px; /* Add padding as needed */
}
/* .centered-button {
    background-color: rgb(255, 255, 255);
    color: #000;
    height: 26px;
    width: 150px;
    text-align: center;
    position: absolute;
    left: 47%;
    z-index: 1;
    font-weight: 900;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: 0;
    border: 1px solid rgb(204, 204, 204);
} */

.centered-button {
  background-color: rgb(255, 255, 255);
  color: #000;
  height: 4.5vh;
  width: 11vw;
  text-align: center;
  position: absolute;
  /* left: 578px; */
  left: 47%;
  z-index: 1;
  /* top: 500px; */
  font-weight: 900;
  /* border-radius: 15px; */
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  bottom: 0;
  border: 0.1vh solid rgb(204, 204, 204);
  font-size: 2vh;
}

.custom_container {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
}

.form-control{  
  display: block;
  width: 100%;
  height: 30px;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.trash-icon {
  margin-left: auto;
}
.navbarIcons {
  background-color: transparent;
  border: none;
  padding: 0.370rem 0.30rem;
}

.navbarIcons:hover {
  background-color: white
}
.navbarIcons:active{
  background-color: white;
}
.custom-dropdown.active .dropdown-toggle {
  background-color: #ddd; /* Set your desired background color when clicked */
}

/* chatbot customisation */
img.rcw-open-launcher {
  height: 135px;
  width: auto;
}

.rcw-widget-container > .rcw-launcher , .rcw-conversation-container .rcw-header{
  background: rgb(1, 169, 130) !important;
  color: rgb(255, 255, 255);
  font-size: 12px;
}


.rcw-close-widget-container > .rcw-launcher {
  background: none !important;
  color: rgb(255, 255, 255);
  box-shadow: none !important;
  /* margin: 0 20px 20px 0; */
  position: fixed;
  bottom: 32px;
  right: 5px;
  height: 110px;
  width: 110px;
}
.chatbot-container-channel-partner .rcw-close-widget-container > .rcw-launcher {
  background: none !important;
  color: rgb(255, 255, 255);
  box-shadow: none !important;
  /* margin: 0 20px 20px 0; */
  position: fixed;
  bottom: 32px;
  right: 5px;
  height: 12vh;
  width: 2vw;
}

.rcw-widget-container-bot-img {
  display: flex;
  position: relative;
  z-index: 1 !important;
  justify-content: center;
}

.rcw-widget-container-bot-img > img {
  width: 40%;
}

.rcw-widget-container {
  position: relative !important;
  margin : -6px 0 0 0 !important
}
.rcw-launcher .rcw-badge {
  top: 10px !important;
  right: 17px !important;
  width: 17px !important;
  height: 15px !important;
  font-size: 12px !important;
  line-height: 17px !important;
}

.chatbot-container-channel-partner .rcw-launcher .rcw-badge {
  top: 5vh !important;
  right: 7vw !important;
  /* right: 15px !important; */
  width: 1vw !important;
  height: 2.5vh !important;
  font-size: 1.5vh !important;
  line-height: 3vh !important;
}
/*  */

.chatbot-container {
  position : absolute; 
  bottom : 10px;
  right : 35px ;
  display : flex ; 
  flex-direction : column-reverse;
  max-width : 22vw;
  z-index: 5;
  /* margin: 0 20px 40px 0 !important; */
}


.rcw-conversation-container {
  min-width: 23vw !important;
  max-width: 23vw !important;
 /*  right: 1vw !important; */
}

.Custom_Widget_Ho .rcw-conversation-container {
  min-width: 23vw !important;
  max-width: 23vw !important;
  right: 16vw !important; 
  margin-top: 10% !important;
  z-index: 10 !important;
}

.chatbot-container-channel-partner .rcw-conversation-container {
  min-width: 23vw !important;
  max-width: 23vw !important;
  right: 1vw !important;
}
.chatbot-container-channel-partner .rcw-conversation-container {
  min-width: 23vw !important;
  max-width: 23vw !important;
  right: 1vw !important;
}

#searchpanel {
  padding: 0 !important;
}

#searchpanel > .container {
  min-height: 70px;
}

.homepage-search-btn {
  position: absolute;
    top: 0;
    right: 62px;
    height: 100%;
    width: 110px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/* body {
  background: url(/src/img/NewHome/MicrosoftTeams-image_12.png) no-repeat;
  background-size: cover !important;
  background-position: center;
  background-clip: content-box;
  background-attachment: scroll;
  max-width: 1600px;
  width: 100%;
  height: 100vh;
} */


/*==================book button css =========*/
.right{
  position: relative;
}

.left,.right{
  height: 100vh;
  width: 100%;
}

.modal.fade.zoom:not(.show) .modal-dialog {
  transform: scale(0.2);
}

.modal-dialog {
  margin: 0.75rem auto;
}

.self-area .books button {
  background-color: transparent;
  border: 0;
}

/* .self-area{
  display: grid;
  grid-template-rows:repeat(2,1fr);
  grid-gap: 2em;
  padding: 1%;
  box-sizing: border-box;
  position: absolute;
  top: 3em;
  right: 3em;
  max-width: 280px;
  width: 100%;
} */

.self-area{
  background: url(/src/img/NewHome/bookshelf.png) no-repeat center;
  background-size: cover;
  display: grid;
  grid-template-rows:repeat(2,1fr);
  grid-gap: 0.3em;
  padding: 0;
  box-sizing: border-box;
  position: absolute;
  top: 0em;
  right: 3em;
  min-width: 350px;
  Max-width: 350px;
  width: 100%;
}

.bookshelf-up{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(120px, auto);
  column-gap: 0.4em;
}
.bookshelf-up button{
  width: 100%;
  height: 100%;
}
.bookshelf-down{
  width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(120px, auto);
    column-gap: 0.3em;
}
.bookshelf-down button{
  width: 100%;
  height: 100%;
}

.bookshelf-up .btn-primary:not(:disabled):not(.disabled):active , .bookshelf-down .btn-primary:not(:disabled):not(.disabled):active{
  background-color: transparent;
  border-color: transparent;
}

.homepage-books-modal > .modal-content {
  background-color: transparent !important;
  border: none !important;
}

.homepage-books-modal > .modal-body {
  padding: 0 !important;
}

.homepage-books-modal > .thumbnails {
  display: none;
}

.homepage-book-modal_close {
  align-items: center;
  background-color: #f0f8ff;
  border-radius: .25rem;
  box-sizing: initial;
  display: flex;
  float: right;
  height: 4vh;
  justify-content: center;
  opacity: 1;
  padding: .25em;
  width: 2.2vw;
}

.capsule-container {
    padding: 0 !important;
}

@media only screen and (max-width:2000px) {
  /* .self-area{
      display: grid;
      grid-template-rows:repeat(2,2fr);
      grid-gap: 5em;
      padding: 1%;
      box-sizing: border-box;
      position: absolute;
      top: 7em;
      right: 4em;
      max-width: 400px;
      width: 100%;
  } */
  /* .bookshelf-up{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: minmax(100px, auto);
      column-gap: 0.8em;
  }
  .bookshelf-down{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: minmax(100px, auto);
      column-gap: 0.8em;
  } */
}
/* @media only screen and (max-width:1600px) {
  .self-area{
      display: grid;
      grid-template-rows:repeat(2,2fr);
      grid-gap: 4em;
      padding: 3%;
      box-sizing: border-box;
      position: absolute;
      top: 8em;
      right: 5em;
      max-width: 360px;
      width: 100%;
  }
} */
/* @media only screen and (max-width:1400px) {
  .self-area {
      display: grid;
      grid-template-rows: repeat(2,1fr);
      grid-gap: 2em;
      padding: 2%;
      box-sizing: border-box;
      position: absolute;
      top: 4em;
      right: 3em;
      max-width: 310px;
      width: 100%;
  }
} */
/* @media only screen and (max-width:1300px) {
  .self-area {
      display: grid;
      grid-template-rows: repeat(2,1fr);
      grid-gap: 1em;
      padding: 2%;
      box-sizing: border-box;
      position: absolute;
      top: 6em;
      right: 4em;
      max-width: 290px;
      width: 100%;
  }
} */

/*==================book button css ends=========*/
/*================= Left content ==============*/

.left{
  position: relative;
}
.left-btn{
  position: absolute;
  width: 100%;
  height: 229px;
  padding: 0px 19px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  left: 0;
  bottom: 150px;
  flex-direction: row;
  grid-column-gap: 25px;
  column-gap: 21px;
}

.feed{
  max-width: 160px;
  width: 100%;
  height: 112px;
  margin-top: 140px;
}
.knowlwdge{
  max-width: 177px;
  width: 100%;
  height: 184px;
  margin-bottom: 0px;
}
.sol360{
    max-width: 120px;
    width: 100%;
    height: 193px;
    margin-bottom: 0px;
}

@media screen and (min-width:1400px) and (max-width:1600px) {
 .feed{
  max-width: 150px;
  width: 100%;
  height: 150px;
  margin-top: 100px;
  margin-left: 30px 
 }   
}

/*================= Left content ends==============*/
.vr{
  border: 2px solid transparent;
  position: absolute;
  bottom: 13em;
  left: 20em;
  max-width: 120px;
  width: 100%;
  height: 70px;
}
.vr button{
  width: 100%;
  height: 100%;
}


/* ---------------------------- Media query for homepage ----------------------------- */

@media screen and (min-width:1300px) and (max-width:1400px) {
  .knowlwdge {
      max-width: 200px;
      width: 100%;
      height: 150px;
      margin-bottom: 20px;
  }
}
@media screen and (min-width:1400px) and (max-width:1550px) {
  .knowlwdge {
      max-width: 230px;
      width: 100%;
      height: 150px;
      margin-bottom: 20px;
  }
  .sol360 {
      max-width: 120px;
      width: 100%;
      height: 188px;
      margin-bottom: -5px;
  }
  .feed {
      max-width: 143px;
      width: 100%;
      height: 150px;
      margin-top: 102px;
  }
  .vr {
      bottom: 14em;
      left: 23em;
      max-width: 120px;
      width: 100%;
      height: 81px;
  }
}
@media screen and (min-width:1550px) and (max-width:1750px) {
  .knowlwdge {
      max-width: 240px;
      width: 100%;
      height: 150px;
      margin-bottom: 100px;
  }
  .sol360 {
    max-width: 150px;
    width: 100%;
    height: 188px;
    margin-bottom: 95px;
  }
  .feed {
    max-width: 173px;
    width: 100%;
    height: 150px;
    /* margin-top: 47px; */
    margin-bottom: 29px;
  }
  .vr {
    bottom: 15em;
    left: 26em;
    max-width: 150px;
    width: 100%;
    height: 81px;
  }
}
@media screen and (min-width:1750px) and (max-width:2000px) {
  .vr {
    bottom: 16em;
    left: 32em;
    max-width: 150px;
    width: 100%;
    height: 95px;
  }
  .sol360 {
      max-width: 170px;
      width: 100%;
      height: 210px;
      margin-bottom: 204px;
  }
  .knowlwdge {
      max-width: 300px;
      width: 100%;
      height: 150px;
      margin-bottom: 180px;
  }
  .feed {
      max-width: 196px;
      width: 100%;
      height: 150px;
      margin-top: -41px;
  }
}

/*-----------------------------Media query ends-----------------------------------*/
.contributionCss{
  left: -111%!important;
  top:0% !important;
}
.projectsCss{
  left: -131% !important;
  top:0% !important;
  max-width: 300px;
}
.sharedCss{
  left: -130.5% !important;
  top:0% !important;
  max-width: 250px;
}
.last15Css{
  left: -130% !important;
  top:0% !important;
  max-height: 200px;
  overflow-y: auto;
  max-width: 250px;
}
.bookmarkCss{
  left: -130% !important;
  top:0% !important;
  overflow-x: hidden;
  max-height: 200px;
  overflow-y: auto;
  max-width: 250px;
}
.bookMarkNodata{
  left: -83% !important;
  top:0% !important;
  max-width: 200px;
}


#img-mapper {
 position: absolute !important;
}

#img-mapper area , .homepage-profile-avatar {
  cursor: pointer;
}

/* .internal-user-home-search {
  position: relative;
  z-index: 2;
} */

#controls {
  position: relative;
  z-index: 1;
}

.deltaFeedbackModal {
  max-width: 600px !important;
}

.deltaFeedbackModal .modal-content , .deltaHelpVideosModal .modal-content , .deltaUserProfileModal .modal-content {
  background-color: transparent !important;
  border: none !important;
}

.deltaFeedbackModal .document-data-modal {
  max-height: unset;
}

.deltaFeedbackModal .modal-header , .deltaHelpVideosModal .modal-header , .deltaUserProfileModal .modal-header {
  border-bottom: none !important;
  justify-content: end !important;
}

.deltaFeedbackModal .modal-header .close , .deltaHelpVideosModal .modal-header .close , .deltaUserProfileModal .modal-header .close{
  padding: unset !important;
  margin: unset !important;
}




.deltaUserProfileModal .modal-body {
  padding: 2.5rem !important;
}

#LanguageSelectionUserProfileModal {
  z-index: 99999;
}

.homepage-helpvideo-row  , .homepage-userprofile-row{
  height: 90%;
  align-items: center;
}

.homepage-helpvideo-row {
  width: 90%;
}

.homepage-userprofile-row .card-title-us , .guestPersonaModal .card-title-us {
  color: rgb(21, 115, 71);
}

.homepage-helpvideos-helptext {
  font-size: 24px;
  color: rgb(21, 115, 71);
}

.homepage-helpvideo-row .card , .homepage-userprofile-col .card_us , .homepage-userprofile-col .card-header_us {
  background-color: transparent !important;
}

.homepage-helpvideo-row .card-header {
  border: none !important;
  border-bottom: 1px solid !important;
  border-radius: 0 !important;
}

.homepage-userprofile-col .card_us {
  border: none !important;
  box-shadow: none !important;
}

.homepage-helpvideo-row .collapse.show {
  border-bottom: none !important;
}

.notepad {
  background-image: url(/src/img/NewHome/ModalBg/notepad-bg1.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 520px;
  height: 640px;
  display: flex;
  align-items: center;
}

/* Home Page - SideBar Menu - START */

#homepage-menu {
  width: 100%;
}

#slide-menu {
  width: 100%;
}

#slide-menu {
  width: 350px;
}

#slide-menu {
  height: 100vh;
  position: absolute;
  top: 68px;
  right: -100%;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 9999;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  transition-duration: 0.6s;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);

}

#slide-menu.active {
  right: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 88vh;
}

.slide-menu-close {
  float: left;
  font-size: 1.5rem !important;
  cursor: pointer;
  margin: 1rem 1rem 0;
  width: 40px;
  height: 40px;
  transition: opacity .25s ease;
  z-index: 9;
  margin-top: 14px;
  background: transparent;
  border-radius: 50%;
  display: flex;
  align-items:center;
  justify-content: center;
  cursor: pointer;
}

.slide-menu-close span:nth-of-type(3) {
  top: 28px;
}

.slide-menu-close p {
  top: 10px;
}

.primary-menu {
  position: relative;
  right: 0;
  top: 5%;
  left: 0px;
  padding-bottom: 30%;
  margin: 0 0 1rem;
  position: absolute;
  right: -1rem;
  top: 10%;
}

.homepage-menu-img {
  margin-right: 10px;
  width: 14%;
}

.homepage-accordion-btn {
  color: #002d62 !important;
  float: left;
  font-size: 0.9rem !important;
  padding: 0.8rem 1.8rem 0.8rem 1.8rem !important;
  font-weight: 700;
  text-decoration: none;
  width: 100%;
  border-bottom: .1rem solid rgba(102, 102, 102, .2) !important;
  background: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homepage-accordion-btn:hover,
.homepage-accordion-btn:focus,
.homepage-accordion-btn.active {
  color: #0d2481;
  text-decoration: none;
}

.homepage-submenu-items {
  background: #efefef !important;
  -webkit-box-shadow: inset 0 0.3rem 0.6rem 0 rgb(0 0 0 / 24%);
  box-shadow: inset 0 0.3rem 0.6rem 0 rgb(0 0 0 / 24%);
  padding: 0;
  max-height: 300px;
  overflow-y: auto;
  max-width: 95.5%;
  margin-bottom: 0 !important;
}

.homepage-submenu-clearall {
  box-shadow: none !important;
  -webkit-box-shadow : none !important
}

.homepage-submenu-clearall a {
  text-decoration: underline !important;
}

.homepage-submenu-clearall li {
  padding: 10px 0 10px 25px !important;
}

.homepage-submenu-items i {
  color: #03145d !important;
}

.homepage-submenu-items li {
  width: 100%;
  padding: 15px 10px 15px 25px;
  border-bottom: 0.1rem solid rgba(102, 102, 102, .2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homepage-submenu-items li a {
  font-size: 0.7rem !important;
  font-weight: 600;
  color: #03145d !important;
  display: block;
  text-decoration: none;
  padding-right: 5px;
}

.homepage-submenu-collapse .projectstext {
  background: #efefef !important;
  -webkit-box-shadow: inset 0 0.3rem 0.6rem 0 rgb(0 0 0 / 24%);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-submenu-collapse .projects-dropdown {
  margin-left: unset !important;
}

/* Home Page - SideBar Menu - END */

.homepage-profile-tooltip {
  border-bottom: none !important;
}

.homepage-profile-persona {
  font-size: 0.8rem;
}

.homepage-profile-persona-select {
  width: 100% !important;
}

.homepage-profile-persona .input-group {
  padding-left: 5px;
}
.react-select-container.customContainer{
  width: "100% !important";
}

/* Guest Persona */
.guestPersonaModal {
  display: flex;
  justify-content: center;
}

.guestPersonaModal .tile {
  margin: 0 auto;
  position: relative;
  transform-style: preserve-3d;
  transform-origin: center right;
  transition: all 0.5s;
  width: 700px;
  height: 500px;
  color: #000;
  font-family: sans-serif;
  box-shadow: 0px 0px 25px -15px rgba(66, 68, 90, 1);
}

.guestPersonaModal .modal-content {
  width: 59vw !important;
  background-color: transparent !important;
  border: none !important;
}

.guestPersonaModal .modal-body {
  padding: 0;
}

.tile__face {
  display: flex;
  justify-content: center;
  
  position: absolute;
  width: 100%;
  height: 100%;
  
  font-size: 1rem;
  
  backface-visibility: hidden; /*hide element on back*/
  }

.tile__face--back {
  background-color: #fff;
  
  /*  rotated on start so the front is visible first */
  transform: rotateY(180deg);
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  }
  .tile__face--front{
      background-color: #f1f1f1;
  }
  .tile__face--front img {
      max-width: 300px;
      width: 100%; 
      height: 100%;
  }
  .tile__face--front .card-content {
      width: 100%; 
      height: 100%;
      padding: 16px;
  }

  .tile.is-flipped {
    transform: translateX(-100%) rotateY(-180deg);
}

.guestPersonaModal .modal-header {
  justify-content: end !important;
  border: none !important;
}
.clearAll-cursor {
  cursor: pointer;
}
/*================Welcome banner==================*/
.guestHead.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: unset;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.delta.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} 


.book-wrapper{
  position: relative;
  max-width: 850px;
  width: 100%;
  /* padding: 20px 12px; */
  height: 82vh;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scene {
    max-width: 500px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.book {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.page {
  /* cursor: pointer; */
  position: absolute;
  color: black;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  transition: 1.5s transform;
  transform-style: preserve-3d;
  transform-origin: left center;
  font-size: 0.865rem;
}

.btn-light {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #f8f9fa;
}
/* 
.btn-save{
  background-color: #01765b;
  color: #f1f1f1;
  border-radius: 0;
}
.btn-cancel{
  border:1px solid #01765b;
  border-radius: 0;
} */
.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  backface-visibility: hidden;
  background: -webkit-gradient(linear, 0% 0%, 100% 100%, from(#FFFFFF), to(#CCCCCC));
  background: linear-gradient(to bottom right, #fff, #ccc);
}
.back {
  transform: rotateY(180deg);
}

.page.active {
  z-index: 1;
}
.page.flipped {
  transform: rotateY(-180deg);
}
.page.flipped:last-of-type {
  z-index: 1;
}

/* p {
  line-height: 1.7;
} */


.flipBtn {
  margin: 0 auto;
}
.flipBtn button{
  background-color: #01765b;
  padding: 9px 16px;
  border: 0;
  color: #f1f1f1;
  font-size: 1.02rem;
  cursor: pointer;
}
body #noScroll-bar{
  height: auto;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto !important;
  position: absolute;
}
.modal-border{
  border: 4px solid rgb(25, 25, 112) !important;
}
/* already exist */
/* .doc-capsules {
  max-height: 85vh;
  overflow-y: auto;
} */
.Doc-Depo-Heading:hover{
  color: #0056b3;
  text-decoration: underline;
}

.hide-scrollbar{
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  height: 100vh !important;
}
.toast-header .btn-close {
  display: none;
}
.text-color-dark{
  color: #212529 !important;
}
.text-color-dark:hover{
  color: #0a58ca !important;
}
.a-hover{
  text-decoration:none !important;
}
.a-hover:hover{
  color: #0056b3 !important;
  text-decoration: underline !important;
}
.table-td{
  background-color: #fcfcfd !important;
}
.sow-shared-search {
  width: 567px;
}
.sow-history-row {
  text-decoration: none !important;
}
.sow-history-row:hover {
  text-decoration: underline !important;
  color: #0a58ca;
}
.nav-link {
  /* padding-top: 0.4rem; */
  padding-bottom: 0.39rem !important;
  color: black;
}
#isActive,
#teamShare {
  height: 17px;
  width: 30px;
}
#switch_filter_approve,
#switch_filter_isActive {
  height: 17px;
  width: 30px;
  margin-right: 6px;
  margin-bottom: 6px;
}
.isActive-costmanagement .form-check-input {
  height: 17px;
  width: 28px;
}
#costManagementTable {
  --bs-table-bg: "";
  --bs-table-color: "";
}
#costManagementTable:hover {
  color: white;
}
.bg-solid-white {
  background-color: whitesmoke !important;
}
.sow_closeButton {
  background:none; 
}
.sow_closeButton:after{
  content: "\2716";
  position: relative;
  left: -3px;
  top: -9px;
  font-size: 20px;
  }
  .customToast-closeButton{
    background:none; 
    /* border: #000; */
    --bs-btn-close-focus-shadow:"";
  }
  .customToast-closeButton:after{
    content: "\2716";
    position: relative;
    left: -1px;
    top: -2px;
    font-size: 15px;
    }
  .modal-dialog-border{
    border: 4px solid rgb(1, 169, 130) !important;
    font-size: 15px !important;
  }
  .accordian-div-collapsed:before {
    float: left !important;
    content: "+";
    font-size: 14px !important;
    margin-right: 4px;
    margin-left: 4px;
  }
  .accordian-div-collapsed{
    height: 23px;
    color: #000 !important;
    
  }
  
  .nested-accordian-div-collapsed:before {
    float: left !important;
    content: "+";
    font-size: 14px !important;
    margin-right: 4px;
    margin-left: 4px;
  }
  .nested-accordian-div-collapsed{
    height: 23px;
    font-size: 16px;
  }
  .mb-0>div:before {
    float: left !important;
    font-family: inherit;
    content: "-";
    padding: 0;
    margin: 0;
    margin-left: 1%;
    margin-right: 1%;
    /* margin-bottom: 54.1vh; */
    font-size: 3vh !important;
    font-weight: bold;
    position: relative;
    bottom: 9%;
  }
  .mb-0>div.collapsed:before {
    float: left !important;
    content: "+";
    font-size: 3vh !important;
  }
  /* .mb-0>div:before {
    float: left !important;
    font-family: inherit;
    content: "-";
    padding-right: 5px;
    font-size: 14px !important;
    font-weight: bold;
  }
  .mb-0>div.collapsed:before {
    float: left !important;
    content: "+";
    font-size: 14px !important;
  } */
  .addmore-doc-depo-input-upload {
    opacity: 0;
    position: absolute;
  }
  #shelf-life-date {
    padding: .75rem;
    position: relative;
    width: 100%;
  }
  #shelf-life-date::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  #shelf-life-date-modal {
    padding: .75rem;
    position: relative;
    width: 100%;
  }
  .datechoose::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  .mb-0>div.custom-collapsed {
    float: left !important;
    /* content: "-"; */
    font-size: 14px !important;
  }
  
  .mb-0>div.custom-collapsed:before {
    float: left !important;
    content: "+";
    font-size: 14px !important;
  }
  .mb-0>div.nested-custom-collapsed {
    float: left !important;
    /* content: "-"; */
    font-size: 12px !important;
  }
  
  .mb-0>div.nested-custom-collapsed:before {
    float: left !important;
    content: "+";
    font-size: 12px !important;
  }
  

/* intelligent resourcing */
.intelligent-resourcing_plusicon {
  font-size: 1.2rem;
  color: rgb(0, 154, 130) !important;
  background-color: #fff;
  border-radius: 50%;
}

.intelligent-resourcing_plusicon_error {
  font-size: 1.2rem;
  background-color: #fff;
  border-radius: 50%;
  color : rgb(210 70 46) !important
}

.intelligent-resourcing_plusicon.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.intelligent-resourcing_minusicon {
  font-size: 1.2rem;
  color: rgb(201,65,47, 1) !important;
  background-color: #fff;
  border-radius: 50%;
}

.intelligent-resourcing_minusicon.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.express-view-a{
  text-decoration: none !important;

}

.express-view-a:hover{
  color: #007bff !important;
  text-decoration: underline !important;
}

.intelligent-resourcing-sow-table-header-sticky {
  position: sticky;
  top: -17px;
  z-index: 9;
}

.intelligent-resourcing-info-container {
  background: rgb(11 14 11 / 25%);
  padding: 10px !important;
}

.intelligent-resourcing-tooltip >.tooltip-inner {
  max-width: 100%;
}

.intelligent-resourcing-cardheader {
  border: none !important;
  background: none !important;
  margin: 0px !important;
}

.create-resource-request-btn {
  font-size: 15px !important;
  width: 15rem !important;
}

.help-video-accordian{
  font-size: 15px !important;
  padding: 5px !important;
}
.help-video-accordian:hover{
  text-decoration: underline !important;
}
.footerlinks a{
  text-decoration: none !important;
}

.footerlinks a:hover{
  text-decoration: underline !important;
}
.footer{
  background: #000;
  min-height: 40px;
  padding: 10px;
  color: #fff;
  bottom: 0;
  font-size: 16px;
}

.react-select-2-listbox {
  width: 200px !important;
}

#switch_engagethirdparty {
  height: 17px;
  margin-bottom: 6px;
  margin-right: 6px;
  width: 30px;
}
.pasbox {
  width: calc(100vw - 50px); /* 50px total gap (25px left + 25px right) */
  height: calc(100vh - 80px); /* 120px total gap (70px top + 50px bottom) */
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); /* Right and bottom shadow */
  margin: 14px 25px 30px; /* 70px top, 25px right and left, 50px bottom */
}
.pasbox-text {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 18px;
  color: #333;
}

.pas-tab-line {
  border-right: 1px solid #ccc;
}
.pas-active-button {

  height: 36px;
  margin-bottom: 13px;
  background-color: "#01A982" !important;
  /* color: black !important; */
  font-weight: 600;
  width: 168%;
}
.pas-closed-button {

  height: 36px;
  margin-bottom: 13px;
  background-color: "#01A982" !important;
  /* color: black !important; */
  font-weight: 600;
  width: 148%;
}
.pas-nav-button
{
  width: 201px;
  height: 36px;
  background-color: grey;
  color: black;
  font-weight: 600;
}
/* #searchResultAutoSuggest .react-autosuggest__suggestions-container{
    width: 97% !important;
  position: absolute;
  left: 17px;

} */
#refineSearchAutoSuggest .react-autosuggest__suggestions-container{
  width: 97% !important;
position: absolute;
left: 17px;
}
.rating-summary{
  position: relative;
  bottom: 82px;
  left: 30px;
}
.loading-feedback {
  height: 45px;
  position: fixed;
  top: 40%;
  left: 48%;
  opacity: 0.5;
  z-index: 1;
  }
  .modal-button{
    background-color: #01a982;
    border: #01a982;
  }
.Pas-Activemodal-button
{
  /* background-color: #01a982; */
  /* border: #01a982; */
  /* width: 85px;
  max-width: 153px; */
}
.Pas-Activemodal-button:hover
{
  background-color: #01a982;
  border: #01a982;
  /* width: 85px;
  max-width: 153px; */
}
.Pas-Activemodal-button:disabled
{
  background-color: #92979f;
  border: #01a982;
  /* width: 85px;
  max-width: 153px; */
}
.autoClass {
  width:20%;
}
.pas-file input[type="file"].form-control::file-selector-button {
  display: none;
}

.pas-file input[type="file"].form-control::before {
  content: attr(value) "Select File";
  background-color: rgb(1, 169, 130);
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: white;
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
  pointer-events: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pas-file input[type="file"].form-control.form-control-lg::before {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  margin-inline-end: 1rem;
}
.Pas_Fileupload_Tabel
{
  background-color: red !important;
}
.Pas_AddButton
{
  background-color: rgb(1, 169, 130);
}
.PasFile_Footer
{
  border:none;
}
.pas_FileLabel
{
  font-size: 16px;
}
.DataNotfound {
  text-align-last: center;
}
#projectId .table-filter-parent{
  left: -10%;
}
#accountName .table-filter-parent{
  left: -5%;
}
#projectDescription .table-filter-parent{
  left: -4%;
}

.accordion-table {
  width: 100%;
  height: 279px;
  overflow-y: auto;
  overflow-x: hidden !important;
  font-size: 11px;
}
.accordion-table-ViewAll {
  width: 100%;
  height: 63vh;
  overflow-y: auto;
  overflow-x: hidden !important;
  font-size: 11px;
}
#projectDetailHeader,#projectId, #accountName ,#projectDescription, #projectStatus, #Geo, #Country, #actions{
  background-color: #008080 !important;
}
#projectId{

  background-color: #008080 !important;

}
#fileDetail{
  background-color: #405467 !important;
}

.pasloader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-button{
  background-color: #01A982 !important;
  border-color: #01A982 !important;
}
.pasUploadPopup_YesButton
{
  width: 67px;
  border-color: #01a982 !important;
  background-color: #01a982 !important;
  color: #fff !important;
}
.Pas_uploadbody
{
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif !important
}
.Pas_uploadbody
{
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif !important
}
.Pas_uploadDocHeader
{
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif !important
}
.Pas_TabelCellAlignment
{
  display: flex;
  margin-left: 9px;
}.pas_filetable{  
  position: relative;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  width:133%
}
.PasToolTip .PasToolTiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  font-size: 12px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 110%;
  left: 50%;
  margin-left: -45px;
}

.PasToolTip:hover .PasToolTiptext {
  visibility: visible;
}
.pas_accrodianTableBody
{
  border-style: hidden;
}

.channel-partner-search{
  width: 100%;
}

.channel-partner-search input{
  padding-right: 27px !important;
  border: none !important;
  width: 40rem !important;
  font-style: italic;
  /* transform: skewX(-30deg); */
  font-size: 16px;
  padding-top: 15px;
  margin-right: 10px;
    position: absolute;
    bottom: -0.2rem;;
    left: 1rem;
    background: transparent;
}
.channel-partner-search .clearsearch {
  height: 0px;
  width: 22px;
  border: none;
  background-color: white;
  cursor: pointer;
  position: absolute !important;
  top: -1.4rem !important;
  right: 40px !important;
  font-size: medium;
  font-weight: bolder;
  color: #8c8c8c;
  padding-left: 10px;
  /* transform: skewX(-30deg); */
  font-style: italic;
  /* z-index: 1111; */
}
.custom-owl-dots{
  display: none;
}

.chatbot-container-channel-partner {
  position : absolute; 
  bottom : 8vh;
  right : 7vw ;
  display : flex ; 
  flex-direction : column-reverse;
  max-width : 22vw;
  z-index: 12;
  
  /* margin: 0 20px 40px 0 !important; */
}



/* Code for dots in HPE News start */
.cp-content-container .slick-dots{
  bottom: -4px !important;
  display: flex !important ;
  justify-content: center !important;
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
  color: #000;
}

.content-container .slick-dots{
  bottom: 2px !important;
  display: flex !important ;
  justify-content: center !important;
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
  color: #000;
}

.slick-dots li{
  margin:  0 0 !important;
  width: 16px !important;
}
.slick-dots li button{
  width:1px !important;
  font-size: 4px !important;
}
/* dots color */
.slick-dots li.slick-active button:before {
  border: #01a982 !important;
  color: #01a982 !important;
}
.carousel-container {
  max-width: 800px; 
  margin: 0 auto;
}
/* To adjust the dots width */
.slick-dots li button:before {
  left: -2px !important;
}
/* dot active */
.dot.active {
  background-color: blue;
}

.channel-partner-home-search{
  position: relative;
  z-index: 2;
}

.channel-partner-home-search .search{
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    
  }

  .channel-partner-home-search .search input {
    height: 7vh;
    /* text-indent: 2vw; */
    border: .1vw solid #d6d4d4;
    border-radius: .65vw !important;
}


   .channel-partner-home-search .search input:focus{
    box-shadow: none;
    border:.1vw solid #d6d4d4;
   }

   .channel-partner-home-search .search .fa-search{

    position: absolute;
    top: 20px;
    left: 16px;

   }

   .square-button {
    border-radius: 0; /* Square corners */
    padding: 10px 20px; /* Adjust for desired size */
    width: auto; /* Optional: let button size adapt to content */
    height: auto; /* Optional: let button size adapt to content */
    box-shadow: none; /* Optional: remove shadows for a flatter look */
  }

   .channel-partner-home-search .search button {
    background: #01a983;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 7vh;
    position: absolute;
    right: -0.1vw;
    /* top: 0.3vh; */
    width: 8vw;
    font-size: 1vw !important;
}

   .channel-partner-home-search .search button:hover{
    background: #01765b;

   }
   .channel-partner-home-search .react-autosuggest__input {
    font-size: 1.25vw;
}

   .channel-partner-home-search .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: .85vw;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    max-height: 250px;
    overflow: auto;
    margin-left: 0%;
  }

  .internal-user-home-search input {
    width: 81% !important;
    background-color: #fff !important;
    border: 1px solid #606060 !important;
    height: 44px !important;
    border-radius: 11px !important;
    padding-right: 30px;
    font-size: 18px;
    margin-left: 7.3% !important;
    /* margin-top: 1.3%; */

  }

  .internal-user-home-search input::placeholder {
    font-size: 0.75em;
  }

  .internal-user-home-search #react-autowhatever-1 {
    z-index: 999 !important;
    width: 85% !important;
    margin-left: 64px !important;
    /* margin-right: 100px !important; */
  }

  .internal-user-home-search {
    position: relative;
    z-index: 2;
  }
  /* .channel-partner-modal {

    width: 50% !important;
    max-width: 50% !important;

  } */

  .channel-partner-modal-table {

    width: 70% !important;
    max-width: 70% !important;

  }

  .internal-user-search .react-autosuggest__suggestions-container{
    width: 97% !important;
  position: absolute;
  left: 17px;

}
/* Code for dots in HPE News end */


.channel-partner-head-search .search input{
  height: 50px;
  font-size: 20px;
}

/* .channel-partner-head-search .search .fa-search{
  top: 1.2rem !important;
} */

.channel-partner-head-search .react-autosuggest__suggestions-container{
  margin-left: 0vw;
}
.CP-homepage-searchIcon {
  position: absolute;
  top: 2.5vh !important;
  left: 2.25vh !important;
  font-size: 1vw;
}

/* .owl-theme .owl-nav {
  margin-top: 10px;
  position: relative;
  font-size: 30px;
  color: white;
  top: -132px;
} */

.custom-nav-class{
  visibility: hidden;
}


.document-preview-modal {
  width: 90%;
  
  max-width: 80%;
  height: 75%;
}

.document-preview_iframe {
  height: 380px;
}

.ChannelPartner-centerDiv {
  padding: 50px;
  border: 2px solid green;
  border-radius: 10px;
  text-align: center;
  background-color: white;
  left: 40%;
  position: absolute;
  margin-top: 15%;
}

.Accelarator-custom-modal
{
  width: 33%;
}

.Pas-AddFilter-button {
  background-color: white;
  color: #009a82;
  border-color: #009a82;
  font-size: 13px !important;
  width: max-content;
  display: flex;
  align-items: center;
}

.pas-filter-dropdown-tab .nav-link {
  color:black !important;
  background-color: white !important;
  font-weight: 700;
}

.pas-filter-dropdown-tab .nav-link.active {
  background-color: #00a982 !important;
  color: #fff !important;
  border-bottom: 2px solid #00a982;
  border-radius: 0px !important;
  padding-bottom: 4px !important;
  min-width: 75px;
  text-align: center;
  border-radius: 3px !important;
}

.pas-filter-dropdown-container {
  position: relative;
  display: inline-block;
}

.pas-filter-dropdown {
  margin-top: 5px;
  width:200px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.pas-filter-dropdown-tab-content {
  width: 100%;
  padding-bottom: 10px;
}

.pas-filter-dropdown-search {
  width: 90%;
  margin-top: 6px;
}

.pas-filter-dropdown-search-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.pas-filter-dropdown-clear-filter{
  background-color:#667d93;
  color: white;
  font-size: 13px !important;
  width: 90%;
  border-color: #ccc8c8;
}

.pas-filter-dropdown-clear-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 5px;
}

.pas-filter-dropdown-options-container {
  margin: 5px 5px 0px 8px;
  min-height: 30px;
  max-height: 130px;
  height: fit-content;
  overflow: auto;
}

.pas-filter-dropdown-options {
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
}

.pas-filter-dropdown-options-no-data {
  padding-top: 10px;
  text-align: center;
}

.pas-filter-dropdown-options > input {
  margin-right: 10px;
}

.pas-filter-dropdown-loader {
  height: 25px;
}

.Pas-AddFolder-button {
  background-color: white;
  color: #009a82;
  border-color: #009a82;
  font-size: 15px !important;
  width: 35px;
}

.Pas-AddFolder-button:active {
  background-color: #009a82 !important;
  color: #fff !important;
  border-color: #fff !important;
  font-size: 15px !important;
  width: 35px;
}

.Pas-AddFolder-button:hover {
  background-color: #009a82 !important;
  color: #fff !important;
  border-color: #fff !important;
  font-size: 15px !important;
  width: 35px;
}
.Pas-AddFolder-button-disabled {
  background-color: #D3D3D3 !important;
  color: #fff !important;
  border-color: #D3D3D3 !important;
  font-size: 15px !important;
  width: 35px;
  cursor: default !important;
}

.Pas-RemoveFolder-button {
  background-color: #009a82 !important;
  font-size: 15px !important;
  width: 35px;
}

.chatbot-container-channel-partner img.rcw-open-launcher {
  height: 155px;
  width: auto;
}
.PasFileDetails.nav-tabs .nav-link {
  color:black !important;
  background-color: white !important;
  font-weight: 700;
}

.PasFileDetails.nav-tabs .nav-link.active {
  background-color: #00a982 !important;
  color: #fff !important;
  border-bottom: 2px solid #00a982;
  border-radius: 0px !important;
  padding-bottom: 4px !important;
  min-width: 100px;
  text-align: center;
  border-radius: 3px !important;
}
.pas-add-btn
{
  margin-right: 8px;
}

.chatbot-container-channel-partner  .rcw-widget-container {
 
  /* height: 42vh !important; */
}

.chatbot-container-channel-partner .rcw-widget-container > .rcw-launcher {
  position: relative;
  right: 0;
  z-index: 99;
}

.chatbot-container-channel-partner .rcw-close-widget-container > .rcw-launcher {
  background: transparent !important;
}

.chatbot-container-channel-partner img.rcw-open-launcher {
  position: absolute;
    height: 28vh;
    right: 4.2vw;
    bottom: -11.5vh;
    width: auto;
}


.cp-content-container {
  position: static;
}


.book-wrapper-guest {
  position: relative;
  max-width: 51vw !important;
  width: 51vw !important;
  padding: 20px 12px;
  height: 100vh;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.frontGuest{
  width: 26vw !important;
}
.custom-modal-replacefile {
  width: 50%; /* Customize the width as needed */
  height: 50%; /* Customize the height as needed */
  max-width: none; /* Allow the width to exceed Bootstrap's default max width */
}

.frontGuest, .backGuest{
  width: 36vw !important;
}

.table-border-remove td, .table-border-remove th, .table-border-remove tr {
  border: none !important;
  outline: none !important;
}
.Pas_github_icon
{
  height: 17px;
  cursor: pointer;
  align-self: center;
  margin-right: 5px;
}
.Pas_Clipboard_Alert
{
  padding-top: 8px;
    padding-bottom: 8px;
}
.Pas_Link_Icon
{
  margin-right: 5px;
}
.Pas_GitHubLink_Label
{
  align-content: center;
    font-size: 14px;
    padding-left: 19px;
}
.Pas_ClipBoard_Icon
{
  background-color: #8e8e8e;
  color: white;

}
.Pas_GitHubLink_input
{
  /* border-right: none; */
  width:100% !important;
  
}

.multiselectormetadata .search-wrapper {
  padding: 5px !important;
}

.chatbot-container-channel-partner .rcw-launcher .rcw-badge {
  margin-right: -1vh;
  margin-top: -4vh;
  position: absolute;
}
/* Added the code for fixing the badge responsiveness*/
/* @media screen and (max-width: 1280px) {
  .chatbot-container-channel-partner .rcw-launcher .rcw-badge {
    margin-right: -3vh;
    margin-top: 0vh;
    position: absolute;
  }
}
@media screen and (min-width: 1400px) {
  .chatbot-container-channel-partner .rcw-launcher .rcw-badge {
    margin-right: -2vh;
    margin-top: -1vh;
    position: absolute;
  }
}
@media screen and (min-width:1600px) and (max-width:1920px) {
  .chatbot-container-channel-partner .rcw-launcher .rcw-badge {
    margin-right: -1vh;
    margin-top: -4vh;
    position: absolute;
  }
} */

.docSummary .modal-content {
  background-color: transparent !important;
  border: none !important;
  width: 100vw !important;
}
.docSummary .modal-header .close {
  padding: unset !important;
  margin: unset !important;
}
.docSummary .modal-header {
  height: 7vh;
  margin-top: 0px;
  border-bottom: none !important;
  justify-content: end !important;
  width: 100vw !important;
} 
.docSummary .modal.show{
  z-index: 99999 !important;
}

 .customBackDrop.show{
  z-index: 9999;
}
.checkboxmargin{
  margin-left: -0.85vw !important;
}
/* .table tbody, table thead
{
    display: block;
} */
.cp-form-control {
  display: block;
  width: 100%;
  height: 5vh;
  padding: 0.1vw 0.1vw;
  font-size: 1vw;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.homepage-profile-avatar.sb-avatar__text div {
  display: table !important;
  table-layout: fixed !important;
  width: 100% !important;
  height: 100% !important;
  font-size: 1.75vh !important;
}


.owl-theme {
  padding-left: 0vh !important;
  padding-right: 0vh !important;
}
.rcw-messages-container {
  height: 40vh !important;
}


@media screen and (max-width: 3500px) {
  .Custom_Widget img.rcw-open-launcher {
    height: 29vh !important;
    width: auto;
    position: absolute;
    right: 20vh !important;
    bottom: -100vh;
}

.Custom_Widget .rcw-launcher .rcw-badge {
  top: 97vh !important;
  right: 7vw !important;
  }
}

@media screen and (max-width: 2000px) {
  .Custom_Widget img.rcw-open-launcher {
    height: 27vh !important;
    width: auto;
    position: absolute;
    right: 21vh !important;
    bottom: -105vh;
}

.Custom_Widget .rcw-launcher .rcw-badge {
  top: 97vh !important;
  right: 7vw !important;
  }

  .rcw-messages-container {
    height: 46vh !important;
  } 

  .Custom_Widget .rcw-launcher .rcw-hide-sm {
    display: none;
  }

  .Custom_Widget .rcw-conversation-container {
    min-width: 31vw !important;
    z-index: 9999;
  }

  .Custom_Widget {
    position: absolute;
    z-index: 999999;
  }
  .Custom_Widget .rcw-launcher {
    margin-bottom: 37px;
  }

/*   .Custom_Widget .rcw-widget-container {
    bottom: 300px !important;
    height: 52vh !important;
  } */

  .Custom_Widget .rcw-messages-container {
    height: 52vh !important;
    z-index: 999999;
  }

  .Custom_Widget .rcw-hide-sm_custom {
    display: none;
  }

  .Custom_Widget .rcw-hide-sm {
    position: absolute;
    z-index: 999999;
    /* right: 346px; */
    /* top: 59px; */
    font-size: large;
    background: transparent !important;
    color: white;
    right: 7px;
    top: -10px;
    box-shadow: none !important;
  }

  .Custom_Widget .rcw-open-launcher {
    animation-duration: 0ms;
  }

  .custom-rcw-open-launcher {
    animation-duration: 0ms;
  }
}

@media screen and (max-width: 800px) {
  
  .Custom_Widget img.rcw-open-launcher {
   height: 29vh !important;
   width: auto;
   position: absolute;
   right: 21vh !important;
   bottom: -109vh;
 } 
 
 .Custom_Widget .rcw-launcher .rcw-badge {
   top: 107vh !important;
   right: 7vw !important;
   }
   .rcw-messages-container {
     height: 42vh !important;
   } 

   .Custom_Widget .rcw-launcher .rcw-hide-sm {
    display: none;
  }

  .Custom_Widget .rcw-conversation-container {
    min-width: 26vw !important;
    z-index: 9999;
    /* max-width: 23vw !important; */
  }

  .Custom_Widget {
    position: absolute;
    z-index: 999999;
  }
  .Custom_Widget .rcw-launcher {
    margin-bottom: 37px;
  }
  .Custom_Widget .rcw-messages-container {
    height: 49vh !important;
    z-index: 999999;
  }

  .Custom_Widget .rcw-hide-sm_custom {
    display: none;
  }

  .Custom_Widget .rcw-hide-sm {
    position: absolute;
    z-index: 999999;
    /* right: 346px; */
    /* top: 59px; */
    font-size: large;
    background: transparent !important;
    color: white;
    right: 7px;
    top: -10px;
    box-shadow: none !important;
  }

  .Custom_Widget .rcw-open-launcher {
    animation-duration: 0ms;
  }

  .custom-rcw-open-launcher {
    animation-duration: 0ms;
  }
}

@media screen and (max-width: 1200px) {
  
  .Custom_Widget img.rcw-open-launcher {
   height: 29vh !important;
   width: auto;
   position: absolute;
   right: 21vh !important;
   bottom: -109vh;
 } 
 
 .Custom_Widget .rcw-launcher .rcw-badge {
   top: 107vh !important;
   right: 7vw !important;
   }
   .Custom_Widget .rcw-messages-container {
       height: 44vh !important;
       z-index: 999999;
   }
 }

 @media screen and (min-width:1500px) and (max-width:1700px) {
  
  .Custom_Widget img.rcw-open-launcher {
   height: 29vh !important;
   width: auto;
   position: absolute;
   right: 21vh !important;
   bottom: -109vh;
 } 
 .Custom_Widget .rcw-launcher .rcw-badge {
  top: 103vh !important;
  right: 7vw !important;
  }
  /* .Custom_Widget .rcw-launcher .rcw-badge {
    top: 55vh !important;
    right: 7vw !important;
  }  */
/* img.rcw-open-launcher {
  height: 28vh !important;
  width: auto;
  position: absolute;
  bottom: -7vh;
  right: 7vh;
}*/
  .rcw-messages-container {
    height: 44vh !important;
  } 

  .Custom_Widget .rcw-launcher .rcw-hide-sm {
    display: none;
  }

  .Custom_Widget .rcw-conversation-container {
    min-width: 31vw !important;
    z-index: 9999;
    /* max-width: 23vw !important; */
  }

  .Custom_Widget {
    position: absolute;
    z-index: 999999;
  }

  .Custom_Widget .rcw-launcher {
    margin-bottom: 37px;
  }

  .Custom_Widget .rcw-messages-container {
    height: 49vh !important;
    z-index: 999999;
  }

  .Custom_Widget .rcw-hide-sm_custom {
    display: none;
  }

  .Custom_Widget .rcw-hide-sm {
    position: absolute;
    z-index: 999999;
    /* right: 346px; */
    /* top: 59px; */
    font-size: large;
    background: transparent !important;
    color: white;
    right: 7px;
    top: -10px;
    box-shadow: none !important;
  }

  .Custom_Widget .rcw-open-launcher {
    animation-duration: 0ms;
    }
  }


  @media screen and (min-width:1200px) and (max-width:1400px) {
  
    .Custom_Widget img.rcw-open-launcher {
     height: 29vh !important;
     width: auto;
     position: absolute;
     right: 21vh !important;
     bottom: -109vh;
   } 
   
   .Custom_Widget .rcw-launcher .rcw-badge {
     top: 107vh !important;
     right: 7vw !important;
     }

     .rcw-messages-container {
      height: 42vh !important;
    } 

    .Custom_Widget .rcw-launcher .rcw-hide-sm {
      display: none;
    }
  
    .Custom_Widget .rcw-conversation-container {
      min-width: 32vw !important;
      z-index: 9999;
      /* max-width: 23vw !important; */
    }
  
    .Custom_Widget {
      position: absolute;
      z-index: 999999;
    }

    .Custom_Widget .rcw-launcher {
      margin-bottom: 37px;
    }
    .Custom_Widget .rcw-messages-container {
      height: 47vh !important;
      z-index: 999999;
    }
  
    .Custom_Widget .rcw-hide-sm_custom {
      display: none;
    }
  
    .Custom_Widget .rcw-hide-sm {
      position: absolute;
      z-index: 999999;
      /* right: 346px; */
      /* top: 59px; */
      font-size: large;
      background: transparent !important;
      color: white;
      right: 7px;
      top: -10px;
      box-shadow: none !important;
    }
  
    .Custom_Widget .rcw-open-launcher {
      animation-duration: 0ms;
    }
  
    .custom-rcw-open-launcher {
      animation-duration: 0ms;
    }
  }
  @media screen and (min-width:1400px) and (max-width:1500px) {
  
    .Custom_Widget img.rcw-open-launcher {
     height: 29vh !important;
     width: auto;
     position: absolute;
     right: 21vh !important;
     bottom: -106vh;
   } 
   
   .Custom_Widget .rcw-launcher .rcw-badge {
     top: 104vh !important;
     right: 7vw !important;
     }
     .rcw-messages-container {
      height: 44vh !important;
    } 
    
    .Custom_Widget .rcw-launcher .rcw-hide-sm {
      display: none;
    }
  
    .Custom_Widget .rcw-conversation-container {
      min-width: 32vw !important;
      z-index: 9999;
      /* max-width: 23vw !important; */
    }
  
    .Custom_Widget {
      position: absolute;
      z-index: 999999;
    }
    .Custom_Widget .rcw-launcher {
      margin-bottom: 37px;
    }
  /* 
    .Custom_Widget .rcw-widget-container {
      bottom: 253px !important;
      height: 52vh !important;
    } */
  
    .Custom_Widget .rcw-messages-container {
      height: 49vh !important;
      z-index: 999999;
    }
  
    .Custom_Widget .rcw-hide-sm_custom {
      display: none;
    }
  
    .Custom_Widget .rcw-hide-sm {
      position: absolute;
      z-index: 999999;
      /* right: 346px; */
      /* top: 59px; */
      font-size: large;
      background: transparent !important;
      color: white;
      right: 7px;
      top: -10px;
      box-shadow: none !important;
    }
  
    .Custom_Widget .rcw-open-launcher {
      animation-duration: 0ms;
    }
  
    .custom-rcw-open-launcher {
      animation-duration: 0ms;
    }
  }
  @media screen and (min-width: 1000px) and (max-width: 1200px) {
  
    .Custom_Widget img.rcw-open-launcher {
     height: 29vh !important;
     width: auto;
     position: absolute;
     right: 21vh !important;
     bottom: -109vh;
   } 
   
   .Custom_Widget .rcw-launcher .rcw-badge {
     top: 107vh !important;
     right: 7vw !important;
     }
     
     .rcw-messages-container {
      height: 42vh !important;
    }
  }  

  img.home-rcw-open-launcher {
    height: 28vh !important;
    width: auto;
    position: absolute;
    right: 2vh !important;
    bottom: -2vh;
}


.homeProject {
  width: 50vh;
  height: 45vh;
  position: absolute;
  right: -7vh;
/*   z-index: 100000; */
  bottom: 9vh;
  background: url(/src/img/ChannelPartner/Chatbot/blow.png) no-repeat center;
  background-size: contain;
  display: block;
}

.rcw-widget-container {
  bottom: 24vh;
  left: 9vh;
 /*  height: 42vh !important; */
}

.rcw-close-widget-container{
  bottom: 0vh !important;
}

.rcw-launcher .rcw-badge {
  position: absolute;
  margin-top: -4vh;
  margin-right: 10vh;
}

.rcw-open-launcher{
  animation-duration: 0ms;
  }

  .rcw-widget-container > .rcw-launcher{
    position: absolute;
    background: transparent !important;
    box-shadow: none !important;   
    right: -3vh;
    }
  button.rcw-launcher{
    position: absolute !important;
  }
  
  .currentCardComponent-height .examples .basic .row {
    padding-top: 1vh !important;
  }
  
  .Custom_Widget .rcw-launcher .rcw-badge {
    top: 98vh !important;
    right: 6vw !important;
  }

  .rcw-message-text {
    font-size: 12px !important;
  }

  .project {
    width: 21vw;
    height: 61vh;
    position: absolute;
    right: -2.3vw;
    z-index: 1;
    bottom: 3vh;
    background: url(/src/img/ChannelPartner/Chatbot/blow.png) no-repeat center;
    background-size: contain;
    display: block;
    }

    .preview_iframe iframe {
      /* background:url(../components/DocumentDepo/img/loading-icon-animated.gif) center center no-repeat ; */
      background-size: 50px 50px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    
    .preview_iframe {
      height: 600px;
    }
    .chatbot-container-channel-partner-search {
  bottom: 5vh !important;
}
.send-notification-btn{
  background-color:"#01a983";
  position:absolute;
  left:7.5vw;
  padding:0 !important;
  margin:0; 
  height:6.5vh;
  width:18vh;
  font-size:2vh !important;
  /* padding-top:5vh; */
  /* padding-bottom:5vh; */
  border-radius: 0 !important;
  border-radius: 3vh !important;
  top: 0;
}


.deltaFeedbackModal {
  /* max-width: 600px !important; */
  height: 89vh;
  margin: 0 !important;
  padding: 0;
  margin-left: 29vw !important;
  margin-top: 10vh !important;

}


.notepad {
  background-image: url(/src/img/NewHome/ModalBg/notepad-bg1.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 56%;
  height: 100%;
  display: flex;
  /* align-items: center; */
  background-size: 100% 100%;
}

.deltaFeedbackModal .modal-content{
  height: 100%;
  width: 70vw;
}
.document-data-modal{
  height: 100%;
  width: 100%;
}
.homepage-book-modal_close_feedback {
  align-items: center;
  background-color: #f0f8ff;
  border-radius: .5vw;
  box-sizing: initial;
  display: flex;
  float: right;
  height: 6vh;
  justify-content: center;
  opacity: 1;
  padding: .25vh .25vw;
  width: 2vw;
  margin-right: 3%;
}
.margin-padding-zero {
  margin: 0 !important;
  padding: 0 !important;
}
.custom-sowfeedbackBtn {
  /*   border: 1px solid rgb(46, 45, 45); */
  border-radius: 0.25rem !important;
  width: 90px !important;
  background-color: #42ae95 !important;
}

.custom-sowfeedbackBtn:hover {
  background-color: #01a982 !important;
}
.sow-feed-back-btn {
  border-width: 0.1vh;
  border-radius: 1.5vh !important;
  padding: 1vh 1vw;
  width: fit-content !important;
  height: fit-content;
  font-size: 2.5vh !important;
  margin-left: 5%;
}
.custom-feedbackBtn {
  /* border: 1px solid rgb(46, 45, 45); */
  border-radius: 0.25rem !important;
  width: 90px !important;
  margin-left: 5px;
}
.btn-properties{
  width: 5vw !important;
  height: 5.5vh !important;
  padding: 0 !important;
  padding: 0.6vh 0.10vw !important;
  border-radius: 0.7vw !important;
}
/* Form */

.sow-feedback-form-wrapper
#feedbackform
.feedback-form-top-half
.sow-feedback-row {
  width: 98%;
  margin: 1.5% 0% !important;
}

#feedbackform #files::-webkit-file-upload-button {
  /* visibility: hidden; */
  border: 0.1vh solid;
  padding: 1% 10% !important;
  margin: 5% !important;
  margin-right: 15% !important;
  /* height: 1vh; */
}
.sow-feedback-row .custom-feedback-field,
.custom-feedback-field:focus {
  height: fit-content;
  border: 0.1vh solid #af94b5;
  border-left: 0.7vh solid #af94b5;
  border-radius: 1vh !important;
  font-size: 2.2vh;
  padding: 1vh !important;
  line-height: 2vh;
}
.custom-feedback-field::placeholder {
  font-size: 2.2vh;
}
.sow-feedback-form-wrapper #feedbackform .sow-feedback-row .red {
  color: red;
  font-size: 3vh;
  position: relative;
  line-height: 0vh;
  top: 0.8vh;
}

.sow-feedback-form-wrapper #feedbackform .sow-feedback-row .helptext2 {
  margin: -1vw !important;
  padding: 0 !important;
  font-size: 2.5vh;
  position: relative;
  left: 0.8vw;
  color: red;
  top: 0.3vh;
}
/* Scrollbar */
.sow-feedback-form-wrapper #feedbackform ::-webkit-scrollbar {
  width: 0.8vh !important;
}

.sow-feedback-form-wrapper #feedbackcontainer,
#feedbackform {
  height: 100%;
  position: relative;
  bottom: 0.8%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 1%;
}
.sow-feedback-form-wrapper #feedbackform .feedback-form-top-half {
  /* height: 50%; */
  width: 94%;
  overflow-y: auto;
}
/* modal */
.sow-feedback-modal {
  /* border: 0.1vh solid red; */
  width: fit-content;
  height: 100%;
  max-width: none;
}

.sow-feedback-modal .modal-body {
  background: url(/src/img/NewHome/ModalBg/notepad-bg1.png) no-repeat center;
  background-size: 100% 100%;
  width: 40vw;
  max-width: none;
  height: 100vh;
  max-height: none;
  padding-top: 7vh !important;
  padding-bottom: 2vh !important;
  margin: 0;
  margin-left: 18vw !important;
}

.sow-feedback-modal .modal-content {
  background: transparent;
  border: none;
  box-shadow: none;
  width: 100%;
  /* border: 0.1vh solid red; */
  height: fit-content;
  width: fit-content;
  border-radius: 0;
  margin-left: 13vw;
}

.sow-feedback-modal .custom-feedbacktitle {
  font-size: 3vh;
  margin: 0;
  margin: 0.5vw 2vw;
  color: teal;
}

.errorMsgFeedback {
  display: flex;
  margin-left: 2px;
  color: crimson;
}
/* to remove */

.sow-feedback-form-wrapper #feedbackform .sow-feedback-row .errorMsgFeedback {
  padding: 0 !important;
  display: flex;
  margin: 0 !important;
  font-size: 2.2vh;
  color: crimson;
}

.sow-feedback-form-wrapper #feedbackform .sow-characters-left {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 2.2vh !important;
  text-align: end;
  padding-right: 8% !important;
}

.sow-feedback-form-wrapper #feedbackform .sow-msg-wrapper {
  margin: 1% 0 !important;
  margin-bottom: 0% !important;
}

.sow-feedback-form-wrapper #feedbackform .fontx12 {
  font-size: 2.2vh !important;
  margin: 1vh;
}

.sow-feedback-form-wrapper #feedbackform .sow-feed-back-btn {
  border-width: 0.1vh;
  border-radius: 1.5vh !important;
  padding: 1vh 1vw;
  width: fit-content !important;
  height: fit-content;
  font-size: 2.5vh !important;
  margin-left: 5%;
}
.sow-feedback-form-wrapper #feedbackform ::-webkit-scrollbar {
  width: 0.8vh !important;
}

.sow-feedback-form-wrapper #feedbackform .custom-feedback-attachment {
  font-size: 2.2vh !important;
  width: 9vw !important
}

.sow-feedback-form-wrapper #feedbackform .feedback-form-bottom-half {
  width: 93%;
  /* margin-top: 2% !important; */
  position: relative;
  bottom: 0%;
  left: 1%;
}
/* Form control */
.feedback_form_control{
  padding: 0 !important;
  margin: 0 !important;
  padding: 0.5vw !important;
  font-size: 1vw !important;
}
.feedback_modal_close{
  padding: 0vw !important;
  margin: 1vw !important;
  font-size: 1.5vw !important;
}

@media screen and (min-width: 1800px) {
  .rcw-message-text {
    font-size: 18px !important;
  }

  .rcw-new-message {
    font-size: 18px !important;
  }
  .rcw-header h4 {
    font-size: 16px !important;
  }

  .rcw-header span {
    font-size: 14px !important;
  }
}

.cp_feedback_gif {
  z-index: 99;
  height: 4vh;
  right: 27vw;
  position: absolute;
  top: 74vh;
  width: 2vw;
  cursor: pointer;
}

.cp_camera_gif {
  z-index: 9;
  height: 6vh;
  left: 22.5vw;
  position: absolute;
  top: 76.5vh;
  width: 2.5vw;
  cursor: pointer;
}

@media screen and (min-width: 1800px) {
  .cp_camera_gif {
    /* z-index: 99; */
    height: 6vh !important;
    left: 22.5vw !important;
    position: absolute;
    top: 75vh !important;
    width: 2.5vw !important;
    cursor: pointer;
  }
  .cp_feedback_gif {
    z-index: 99;
      height: 4.5vh !important;
      right: 27vw !important;
      position: absolute;
      top: 72vh !important;
      width: 2.5vw !important;
      cursor: pointer;
  }
}

.homepage-book-modal_close_help {
  align-items: center;
  background-color: #f0f8ff;
  border-radius: .25rem;
  box-sizing: initial;
  display: flex;
  float: right;
  height: 4vh;
  justify-content: center;
  opacity: 1;
  padding: .25em;
  width: 2.2vw !important;
}


.deltaHelpVideosModal {
  margin: 0 !important;
}

.deltaHelpVideosModal .modal-body {
  background: url(/src/img/ChannelPartner/NewImg/book-bg.webp) no-repeat 100%;
  background-size: cover;
  /* overflow-y: auto; */
  height: 90vh;
  width: 100vw !important;
  margin: 0;
  /* margin-left: -29.5vw;
margin-right: -29.5vw; */
  /* padding-top: 10%; */
}
.deltaHelpVideosModal .modal-content {
  background-color: transparent !important;
  border: none !important;
  width: 100vw !important;
}
.deltaHelpVideosModal .modal-header .close {
  padding: unset !important;
  margin: unset !important;
}
.deltaHelpVideosModal .modal-header {
  height: 7vh;
  margin-top: 0px;
  border-bottom: none !important;
  justify-content: end !important;
  width: 100vw !important;
}

.homepage-helpvideo-col{
  padding: 3rem !important;
  padding-left: 3.5rem !important;
}

.homepage-book-modal_close_btn {
  float: right;
  font-size: "1.2rem";
  opacity: 0.8
}

.hompepage-helpvideo-header {
  border: 0px;
  background-color: transparent !important;
  text-decoration: none;
}

.homepage-helpvideo-close_btn {
  float: right;
  font-size: 2.2vh;
  opacity: 0.8 !important;
  position: relative;
  right: 14.7%;
}

.homepage-helpvideo-icon {
  margin-right: 15px; 
  color:black
}

.homepage-helpvide-card_body {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 10px;
}

.channel-partner-search_header {
  margin-bottom: 10px;
  position: relative
}

.channel-partner_clearsearch {
margin-top: 7px;  
color: blue
}

.channel-partner_resetsearch {
  color: #fff;
  margin-top: 5px;
  margin-left:1vw; 
  cursor: pointer; 
  font-size: 1.2rem
}

.channel-partner-home-search_autosuggest {
  position: relative
}

.channel-partner-home-search_clearsearch {
  position: absolute;
  top: 5%;
  right: 30%;
  cursor: pointer;
}

.channel-partner-home-search_advclearsearch {
  position: absolute;
  top: 58%;
  cursor: pointer;
}

.channel-partner-home-search_box {
  border-radius: 0px;
}

.send-notification_main {
  height:25vh;
  width: 23vw !important;
  overflow :hidden
}

.send-notification_header {
  padding:0;
  margin:0;
  padding-left:1vw !important;
  padding-top:1vh !important;
  padding-right:1vw !important
}

.send-notification_title {
  font-size: 2.5vh !important;
}

.send-notification_body {
  padding:0 !important;
  margin:0;
  overflow:hidden;
  margin-top:3vh
}

.send-notification_ul {
  list-style:none;
  padding:0
}

.send-notification_li {
  position: relative;
}

.send-notification-modal_body {
  max-height: 395px;
  overflow-y: auto;
}

.send-notification-modal_error {
  font-size: 12px;
  color: red;
}

.send-notification-modal_form {
  height: 90px;
}

.channel-partner-chatbot-accordion {
  border-bottom: 0 !important;
}

.channel-partner-chatbot-accordion_div1 {
  text-align: center;
}

.channel-partner-chatbot-accordion_div2 {
  padding: 15px 10px 0 10px;
}

.channel-partner-chatbot-carousel {
  /* max-width: 320px;
  max-height: 180px; */
}

.channel-partner-chatbot-prev {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  height: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  left: 15px;
}

.channel-partner-chatbot-right {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  height: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  right: 15px;
}

.channel-partner-chatbot-indicator {
  background: #fff;
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 2px;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease
}

.channel-partner-chatbot-indicator_selected {
  background-color: #717171;
}

.channel-partner-chatbot-indicator_unselected{
  background-color: #bbb;
}

.channel-partner-chatbot-carousel_title {
  padding-bottom: 25px;
  padding-left: 10%;
  padding-right: 10%;
}

.channel-partner-chatbot-carousel {
  /* padding-top: 20px;
  padding-bottom: 20px;
  height: 150px;
  max-width: 320px;
  max-height: 180px; */
}

.channel-partner-chatbot-carousel_url {
  padding: 20px 15%
}

.channel-partner-chatbot-feedback_img {
  height: 25px;
  width: 25px;
  margin-left: 0px;
  border-radius: 100%
}

.channel-partner-chatbot-feedback_message{
  width: 215px;
}

.channel-partner-chatbot-feedback_close {
  cursor: pointer;
}

.channel-partner-custom-toast {
  height:30px;
  width:30px
}

/* Custom DialogPopup */
.customModal{
  display: block !important;
  top: 24vh !important;
  left: 35.5vw !important;
  border-radius: 0 !important;
}
.customModalOpacity {
  opacity: 1 !important;
}
/* Accelerator */
.acceleratorPaddingBottom{
  padding-bottom: 0px !important;
}
.acceleratorModal{
  height: 70vh !important;
  padding-top: 0rem !important;
}
.accelratorBorder{
  border: none !important;
}
.acceleratorLink{
  width: 300px !important;
  border: 2px solid #01a982 !important;
  background-color: transparent !important;
  margin-left: 20px !important;
  margin-top: 4px !important;
  font: 50px !important;
  font-size: small !important;
}
.acceleratorTabContent{
  max-height: 100% !important;
  overflow-y: auto !important;
  padding: 10px !important;
}
.acceleratorDownload{
  color: white !important;
  text-decoration: none !important;
}
.acceleratorNav{
  border-bottom: none !important;
}
/* ChannelPartnerDocumentModal */
.documentModal-close-button{
  float: right !important;
  font-size: 1.2rem !important;
  opacity: 0.8 !important;
  position: relative !important;
  right: 3px !important;
}
.documentModal-col{
  position: relative !important;
  left: 5vw !important;
}
.documentModal-col-pl-2 {
  padding-left: 2vw !important;
}
.documentModal-col-pl-8 {
  padding-left: 8vw !important;
}

/* ChannelPartnerDocumentsCarousal */
.documentCarousal-tooltip{
  border: 0px !important;
}
.documentCarousal-custom-width{
  position: relative !important;
}
.documentCarousal-width-2-books {
  width: 8.8vw !important;
}

.documentCarousal-width-greater-than-2-presentations {
  width: 23vw !important;
}

.documentCarousal-width-default {
  width: 18vw !important;
}
/* ChannelPartnerHeader */
.channelPartner-fontWeight{
  font-weight: 700 !important;
}
.channelPartner-avtar{
  margin-right: 5vw !important;
}
.channelPartner-facog{
  font-size: 4.5vh !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-right: 1vw !important;
  margin-top: 3vh !important;
}
/*ChannelPartnerHeadSearchModal*/
.headSearch-header{
  padding: 0 !important;
  margin: 0 !important;
}
.headSearch-close-button{
  float: right !important;
  font-size: 2.2vh !important;
  opacity: 0.8 !important;
  position: relative !important;
  right: 14.7% !important;
}
.headSearch-padding{
  padding: 0 !important;
}
.headSearch-col{
  height: 100vh !important;
  margin-left: 3vw !important;
}
.headSearch-maincontainer{
  height: 100% !important;
}
.headSearch-indexsection{
    height: 65% !important;
    width: 81% !important;
    overflow-y: auto !important;
    margin-top: 5% !important;
    font-size: 2vh !important;
}
.headSearch-DS-head-bmodal{
  margin-right: -1vw !important;
  font-size: 2vh !important;
}
/*  */
.headSearchResult-footer{
  width: 100vw !important;
  position: absolute !important;
  bottom: 0 !important;
  padding-top: 1vh !important;
  color: #ffffff !important;
  font-size: 1.5vw !important;
}
.headSearchResult-navbar-img{
  width: 8vw !important;
}
.headSearchResult-header-profile{
  font-size: 4.5vh !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-right: 1vw !important;
  margin-top: 3vh !important;
}
.headSearchResult-head-img{
  position: absolute !important;
  object-fit: contain !important;
  width: 106% !important;
  bottom: 0vh !important;
}
.headSearchResult-head{
  aspect-ratio: 698 / 767 !important;
  position: absolute !important;
  width: 100% !important;
  bottom: 0vh !important;
}
.headSearchResult-loader{
  position: absolute !important;
  left: 27vw !important;
  bottom: -60vh !important;
}
/* CP-knowledge-capsule */
.knowledgeCapsule-text-width{
  left: 6.9vw !important;
}
.knowledgeCapsule-loader{
  position: absolute !important;
  left: 40vw !important;
  bottom: -60vh !important;
}
.knowledgeCapsule-name-plate{
  visibility: hidden !important;
}
.knowledgeCapsule-footer{
  z-index: 0 !important;
}
.knowledgeCapsule-td{
  text-align: center !important;
}
/* Document */
.document-carousal{
  width: 8.8vw !important;
  position: relative !important;
}
/* DocumentAutoSuggestNew */
.autosuggestnew-font{
  font-size: 1vw !important;
}
.non-channel-partner{
  border-radius: 11px !important;
  height: 45px !important;
  width: 84px !important;
  background-color: #157347 !important;
}

.maintanance-main {
  margin-top: 0;
}

.maintanance-link {
  color: blue;
}

.cp-custom-modal-body {
  opacity: 1;
}

.channel-partner-download_file {
  white-space: pre-wrap;
}

.channel-partner-search_box {
  color: #fff;
  margin-top: 18px !important;
  cursor: pointer
}

.channel-partner-search_icon {
  font-size: 18px;
}

.channel-partner-search_modalclose {
  top: 4.125vh;
  left: 0vw;
  position: relative; 
  margin: 0px 2px
}

.homepage-books-modal {
  overflow-y: hidden !important;
}

.channel-partner-search_modalbody {
  bottom: 34px;
  padding: 0;
}

.channel-partner-search_modalclose {
  top: 4.125vh;
  left: 0.08vw;
  position: relative;
  margin: 0px 0.168vw
}

.channel-partner-search_modalcloseicon {
  float: right;
  font-size: 1.2rem;
  opacity: 0.8;
  color: black
}

.channel-partner-search_iframe {
  width: 100%;
  height:95vh;
  border: none;
}

.channel-partner-feedback_summary {
  height: auto;
}

.channel-partner-feedback_content {
  height: 13vh;
}

.channel-partner-feedback_icon {
  margin-right: 3%;
  margin-left: 3%;
  font-size: 2.5vh;
}

.channel-partner-feedback_attachment {
  margin-right: 1.2vw;
  font-size: 2.1vh;
  width: 5.9vw;
}

.channel-partner-feedback_listmain {
  width: 50vw; 
  margin-left: 1.3vw;
  margin-top: 0
}

.channel-partner-feedback_ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.channel-partner-feedback_li {
  margin-bottom: 5px;
  font-size: 2vh;
  text-align: left;
  align-items: center;
}

.channel-partner-feedback_removeatt {
  cursor: pointer;
  color: red;
  margin-left: 2vw;
  font-size: 3vh;
}

.CP-head-rack10 {
  width: 54%;
}

.CP-head-rack11 {
  width: 152%;
}

.CP-head-rack40 {
  width: 110%;
}

.CP-head-rack41 {
  width: 45%;
}

.chatbot-container-channel-partner .rcw-widget-container > .rcw-launcher > .rcw-close-launcher {
  position: absolute;
  top: 50px;
  right: 10px;
}

.rcw-close-launcher {
  width: 16px !important;
}